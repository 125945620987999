
export function getTopXMembers(jsonData, jsonDataMembers, countMembers) {
    try {
        let tmpM = []
        // tworzymy tablicę wszystkich pojedynków
        jsonData.forEach(element => {
            element.matches.forEach(element1 => {
                if (element1.match_details_ex && element1.match_details_ex.matche_ts) {
                    element1.match_details_ex.matche_ts.forEach(element2 => {

                        if (element2.score_member1 !== null && element2.score_member2 !== null && element2.type === 1) {
                            tmpM.push({
                                score_member1: element2.score_member1
                                , score_member2: element2.score_member2
                                , member1_id: element2.team1_member_id
                                , member2_id: element2.team2_member_id
                            })
                        }

                    });
                }
            });
        });

        let tmpMT = []
        // lista membersów w drużynie
        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_id: element.member_id, team_name: element.name, team_img: element.img_file,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        total_points_matches: 0, total_points_winners: 0, total_points_losers: 0, total_points_winners_small: 0, total_points_losers_small: 0
                    })
                });
            }

        });

        if (tmpMT.length > 0 && tmpM.length > 0) {
            // aktualizujemy pojedynki
            tmpMT.forEach(element => {
                let total_points_matches = 0
                let total_points_winners = 0
                let total_points_losers = 0
                let total_points_winners_small = 0
                let total_points_losers_small = 0

                tmpM.forEach(element1 => {
                    if (element.member_id === element1.member1_id) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers++
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                        }

                    } else if (element.member_id === element1.member2_id) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        }
                    }

                })
                element.total_points_matches = total_points_matches
                element.total_points_winners = total_points_winners
                element.total_points_losers = total_points_losers
                element.total_points_winners_small = total_points_winners_small
                element.total_points_losers_small = total_points_losers_small
            })
            return tmpMT.sort((a, b) => b.total_points_winners - a.total_points_winners 
            || a.total_points_losers - b.total_points_losers 
            || (b.total_points_winners_small-b.total_points_losers_small) - (a.total_points_winners_small-a.total_points_losers_small)).slice(0, countMembers)
        }
        else {
            return []
        }


    } catch (e) {
        console.log(e)
        return []
    }
}


export function getMembersStatsLeague(member_id, jsonData) {
    try {

        if (jsonData.matches) {


         
            let total_points_winners = 0
            let total_points_losers = 0
            let total_points_winners_small = 0
            let total_points_losers_small = 0
            // tworzymy tablicę wszystkich pojedynków
            jsonData.matches.forEach(element => {
                if (element.matche_ts) {
                    element.matche_ts.forEach(element1 => {
                        if (member_id === element1.team1_member_id  && element1.type===1) {
                    
                            if (element1.score_member1 > element1.score_member2) {
                                total_points_winners++
                                total_points_winners_small = total_points_winners_small + element1.score_member1
                                total_points_losers_small = total_points_losers_small + element1.score_member2
                            } else if (element1.score_member1 < element1.score_member2) {
                                total_points_losers++
                                total_points_losers_small = total_points_losers_small + element1.score_member2
                                total_points_winners_small = total_points_winners_small + element1.score_member1
                            }
    
                        } else if (member_id === element1.team2_member_id  && element1.type===1) {
                       
                            if (element1.score_member1 > element1.score_member2) {
                                total_points_losers++
                                total_points_winners_small = total_points_winners_small + element1.score_member2
                                total_points_losers_small = total_points_losers_small + element1.score_member1
                            } else if (element1.score_member1 < element1.score_member2) {
                                total_points_winners++
                                total_points_winners_small = total_points_winners_small + element1.score_member2
                                total_points_losers_small = total_points_losers_small + element1.score_member1
                            }
                        }
                    });
                }

            });

            return { matches: { win: total_points_winners, lose: total_points_losers }, points: { win: total_points_winners_small, lose: total_points_losers_small } }
        } else {
            return null
        }

    } catch (e) {
        console.log(e)
        return null
    }
}