import bergerTable from 'berger-table-generator';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Checkbox, Grid, Header, Icon, Image,  Message, Modal, Table } from 'semantic-ui-react'
import LogoTeamLigaGenerator from '../../../assets/logotgver1.svg';
import { numberToCharTG, saveCsvTab } from '../../../myfunctions';
import { useReactToPrint } from 'react-to-print';
import { QRCode } from 'react-qrcode-logo';
import tglh from '../../../assets/tglh.png';

function BracketGenHarm(propsRoot) {
    const [open, setOpen] = useState(false)
    const [idt, setidt] = useState(null)
    const [rowData, setrowData] = useState([])
    const [chboxg, setchboxg] = useState(false)
    let tmp = []
    


    function reverseScore(a, b, countMatches) {
        if (Number(a) < Number(b)) {
            return countMatches[b].member_name + " vs " + countMatches[a].member_name
        } else {
            return countMatches[a].member_name + " vs " + countMatches[b].member_name
        }

    }

    function getTimeMatch(a, b, countMatches,rowmatches){

        let m1 = countMatches[a].tournament_member_id
        let m2 = countMatches[b].tournament_member_id
        if(rowmatches){
            for (const row of rowmatches) {
                
                if((m1===row.tournament_member1_id && m2===row.tournament_member2_id) || (m2===row.tournament_member1_id && m1===row.tournament_member2_id)){
                    
                    if(row.match_place){
                        
                        return <div style={{fontSize:12,lineHeight:"14px"}}>
                       {JSON.parse(row.match_place).place_desc ? <> <Icon color='grey' name="point"/>{JSON.parse(row.match_place).place_desc}<br/> </>: ""}
                        <Icon color='grey' name="calendar"/>{JSON.parse(row.match_place).match_dt}<br/>
                        </div>
                    }else{
                        return ""
                    }
                }
              }    
        }
        
    }

    function generatorMatchCount(countMatches, group_name,rowmatches) {
        try {
            let arr = bergerTable(countMatches.length)
            if (arr && arr.length > 0) {
                arr.forEach((row) => {
                    row.forEach((row1) => {
                     
                        tmp.push({ group_name: group_name, match: reverseScore(row1.teamA, row1.teamB, countMatches), match_time: getTimeMatch(row1.teamA, row1.teamB, countMatches,rowmatches) })

                    })
                })
            } else {
                return ""
            }
            setrowData(tmp)
        } catch (err) {
            console.log(err)
        }

    }

    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps

      
        if (propsRoot.show) {
            setOpen(true)
        }
        if(propsRoot.linkTournament){
            setidt(propsRoot.linkTournament)
        }
        if (propsRoot.countMatches && propsRoot.countMatches.length > 0 && propsRoot.countMatches[0].members && propsRoot.show) {
           
            tmp = [] // eslint-disable-line react-hooks/exhaustive-deps
            propsRoot.countMatches.forEach(element => {
                generatorMatchCount(element.members, element.group_name,element.matches)
            });

        }


        return () => {

        }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.show,propsRoot.linkTournament])// eslint-disable-line react-hooks/exhaustive-deps


    function closeModal() {
        setOpen(false)
        propsRoot.setopenModalHarm(false)
    }


   

    const componentRefPrint = useRef();
    const handlePrintTab = useReactToPrint({
        content: () => componentRefPrint.current,
    });

    let group_name="Grupa I"

    function sgroup_name(val){
        group_name=val
        return <div className='page-break'></div>
    }

  
    return (
        <>
            <Modal
                onClose={() => closeModal()}
                open={open}
                dimmer='blurring'
                closeIcon
                size='large'
            >
             
                <Modal.Content scrolling>
             
                    <div ref={componentRefPrint}>
                        <Grid unstackable style={{marginTop:5,marginBottom:5}}>
                            <Grid.Row>
                                <Grid.Column width={12} textAlign="center" verticalAlign="middle">
                                    <Message>
                                    <Header as='h2' >
                                        <Header.Content>
                                            <Icon name="winner"/>
                                            HARMONOGRAM GIER<br/>
                                            <Image style={{ width: 200 }} centered src={LogoTeamLigaGenerator} alt="logo"/>
                                        </Header.Content>
                                    </Header>
                                    </Message>
                                </Grid.Column>
                                <Grid.Column width={4} textAlign="right" >
                      
                                {idt ? <QRCode size={120}  floated="right" value={"https://turniej-generator.pl/tg/" + numberToCharTG(idt)} logoImage={tglh}  ecLevel={"H"}/> :""}
                                    
                                </Grid.Column>
                              

                            </Grid.Row>

                        </Grid>
                       
                        <Icon className='cursorPointer tllink hiddenPrint' title="DRUKUJ" bordered floated name="print" onClick={handlePrintTab} />
                        {saveCsvTab(rowData, "harmonogram.csv")}
                        <Checkbox toggle className='hiddenPrint' checked={chboxg} onChange={(e,d)=>setchboxg(d.checked)} label='Każda grupa na osobnej stronie' />
                        <style type="text/css" media="print">
                            {" @page { size: portrait; } "}
                        </style>
                        <Table style={{ marginTop: 5, fontSize: 14, lineHeight: "11px", marginBottom: 2 }} size='small' celled striped unstackable >
                            <Table.Header>
                                <Table.Row>

                                    <Table.HeaderCell singleLine width={2} textAlign='center'>GRUPA</Table.HeaderCell>
                                    <Table.HeaderCell singleLine textAlign='center'>POJEDYNEK</Table.HeaderCell>
                                    <Table.HeaderCell singleLine width={5} textAlign='center'>ROZPOCZĘCIE</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {rowData.length > 0 ?
                                    rowData.map((row, i) =><>
                                    {group_name!==row.group_name && chboxg ? sgroup_name(row.group_name) :""} 
                                        <Table.Row key={i}>
                                            <Table.Cell >{row.group_name}</Table.Cell>
                                            <Table.Cell textAlign='center'>{row.match}</Table.Cell>
                                            <Table.Cell textAlign='center'>{row.match_time}</Table.Cell>
                                                  </Table.Row>
                                         
                                          </>
                                    )
                                    : ""}

                            </Table.Body>
                        </Table>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => closeModal()}>
                        ZAMKNIJ
                    </Button>

                </Modal.Actions>
            </Modal>
        </>

    )
}

export default BracketGenHarm;