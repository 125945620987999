import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Divider, Button, Checkbox, Grid, Message, Label, List } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import useAuth from '../../../../hooks/useAuth';
import { ConnectWS } from '../../../../ConnectWS'
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import { changeInitialScale, defJsonCFGConfigurator, loadingShow } from '../../../../myfunctions';
import { ColorPicker } from 'primereact/colorpicker';
import { CodeBlock } from 'react-code-blocks';

function WebsiteConfigurator(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [loading, setloading] = useState(false);// eslint-disable-line no-unused-vars
  const [loading1, setloading1] = useState(true);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars

  const isDemo = useCheckUserIsDemo()// eslint-disable-line no-unused-vars

  const [jsonCFG, setjsonCFG] = useState(defJsonCFGConfigurator());
  const [xApiKey, setxApiKey] = useState(null);


  useEffect(() => {
    setloading1(true)
    changeInitialScale(0.5)
    getOrganizerWebsiteCfg()
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: ChangePassword")
    }
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  function getOrganizerWebsiteCfg() {
    setloading(true)
    ConnectWS("dedicated/getOrganizerWebsiteCfg", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        try {
          if (response.data && response.data.site_cfg.bgcolor) {
            setjsonCFG(response.data.site_cfg)

          } else {
            setjsonCFG(defJsonCFGConfigurator())
          }
        } catch (e) {

        }
        try {
          setxApiKey(response.data.x_api_key)
        } catch (e) {

        }

      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloading1(false)
      setloading(false)
    });
  }

  function setOrganizerWebsiteCfg() {
    setloading(true)
    ConnectWS("dedicated/setOrganizerWebsiteCfg", authContext.token, { site_cfg: jsonCFG }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        document.getElementById('siteTGif').src += '';
        document.getElementById('siteTGif1').src += '';
        document.getElementById('siteTGif2').src += '';
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloading(false)
    });
  }




  return (
    <> <Header as='h5' textAlign='center' block>
      <Icon name='globe' />
      <Header.Content>Konfigurator strony gotowej do osadzenia na własnej stronie WWW</Header.Content>
    </Header>
      {loading1 ? loadingShow() : <>
        {xApiKey ? <>

          <Grid stackable>
            <Grid.Row >
              <Grid.Column width={6}>
                PARAMETRY:<br /><br />
                <div ><ColorPicker format="hex" value={jsonCFG.bgcolor} onChange={(e) => setjsonCFG({ ...jsonCFG, bgcolor: "#" + e.value })} /> Kolor tła:{jsonCFG.bgcolor}</div>
                <div style={{ marginTop: 10 }}>
                <ColorPicker format="hex" value={jsonCFG.bgcolor_stats} onChange={(e) => setjsonCFG({ ...jsonCFG, bgcolor_stats: "#" + e.value })} /> Kolor tła statystyk
                <ColorPicker style={{marginLeft:5}} format="hex" value={jsonCFG.label_color_stats} onChange={(e) => setjsonCFG({ ...jsonCFG, label_color_stats: "#" + e.value })} /> Kolor wstążki statystyk
                </div>
                
                <div style={{ marginTop: 10 }}><Checkbox toggle label="Ciemny motyw strony" onChange={(e, d) => setjsonCFG({ ...jsonCFG, dark: d.checked })} checked={jsonCFG.dark ? true : false} /> </div>
                <div style={{ marginTop: 10 }}><Checkbox toggle label="Statystyki" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_statistics: d.checked ? 1 : 0 })} checked={jsonCFG.show_statistics ? true : false} /> </div>
                <div style={{ marginTop: 10 }}><Checkbox toggle label="Informacja o nowych turniejach/ligach" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_new_tournament: d.checked ? 1 : 0 })} checked={jsonCFG.show_new_tournament ? true : false} /> </div>
                <div style={{ marginTop: 10 }}><Checkbox toggle label="Informacja o ostatnim cyklu" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_last_cycle: d.checked ? 1 : 0 })} checked={jsonCFG.show_last_cycle ? true : false} /> </div>
                <div style={{ marginTop: 10 }}><Checkbox toggle label="Lista turniejów/lig" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_all_tournaments: d.checked ? 1 : 0 })} checked={jsonCFG.show_all_tournaments ? true : false} /> </div>
                <div style={{ marginTop: 10 }}><Checkbox toggle label="Lista cykli" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_all_cycles: d.checked ? 1 : 0 })} checked={jsonCFG.show_all_cycles ? true : false} /> </div>
                <div style={{ marginTop: 10 }}><Checkbox toggle label="TOP10 ELO" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_top10_member_elo: d.checked ? 1 : 0 })} checked={jsonCFG.show_top10_member_elo ? true : false} /> </div>
                <div style={{ marginTop: 10 }}><Checkbox toggle label="TOP10 Uczestników z pierwszym miejscem" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_top10_member_winners: d.checked ? 1 : 0 })} checked={jsonCFG.show_top10_member_winners ? true : false} /> </div>
                <div style={{ marginTop: 10 }}><Checkbox toggle label="TOP10 Uczestników na podium" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_top10_member_podium: d.checked ? 1 : 0 })} checked={jsonCFG.show_top10_member_podium ? true : false} /> </div>


                <Divider />
                <div style={{ marginTop: 10 }}><Checkbox toggle label="Uczestnicy - karta osiągnięć" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_members: d.checked ? 1 : 2 })} checked={jsonCFG.show_members === 1 ? true : false} /> </div>
                <div style={{ marginTop: 10 }}><Checkbox toggle label="Uczestnicy - tabela" onChange={(e, d) => setjsonCFG({ ...jsonCFG, show_members: d.checked ? 2 : 1 })} checked={jsonCFG.show_members === 2 ? true : false} /> </div>


                <Button fluid style={{ marginTop: 10 }} onClick={() => setOrganizerWebsiteCfg()} loading={loading} disabled={loading}>ZAPISZ USTAWIENIA</Button>



              </Grid.Column>
              {xApiKey ?
                <Grid.Column width={10} style={{ border: "#828282 solid 1px", borderRadius: 5, padding: 0,position:"relative" }}>
                   <Label color='grey' size='mini' style={{left:2,top:2,position:"absolute"}}>
                      Podgląd:
                    </Label>
                  <iframe id="siteTGif" src={"/widget/site/" + xApiKey} width="100%" height={500} title="Turniej-Generator" frameborder="0" style={{ border: "none" }}></iframe>
                  <a href={"/widget/site/" + xApiKey} target="_blank" rel="noopener noreferrer"><Button fluid size='tiny'>ZOBACZ STRONĘ W NOWYM OKNIE</Button></a>
                </Grid.Column>
                : ""}
            </Grid.Row>


          </Grid>
          <Divider />

          <Header as='h4'>Krok 1: Otwórz kod HTML swojej strony</Header>
          <p>
            Upewnij się, że masz dostęp do pliku HTML swojej strony. Możesz go edytować za pomocą edytora tekstu (np. Notepad++, Visual Studio Code)
            lub za pomocą narzędzi dostępnych w CMS (Content Management System) jeśli używasz takiego systemu (np. WordPress).
          </p>
          <Header as='h4'>Krok 2: Wybierz miejsce, w którym chcesz osadzić stronę</Header>
          <p>
            Zlokalizuj miejsce w kodzie HTML, w którym chcesz osadzić inną stronę.
            Może to być sekcja treści strony lub dowolne inne miejsce, gdzie chcesz, aby iframe się pojawiło.
          </p>

          <Header as='h4'>Krok 3: Dodaj element iframe</Header>
          <p>
            Wprowadź następujący kod HTML w wybranym miejscu:
          </p>

          <Grid stackable>
            <Grid.Row >
              <Grid.Column width={8}>
                <Message >
                  <Label color='blue' >
                    Link dedykowana strona turnieje/liga
                  </Label>
                  <CodeBlock
                    text={'<iframe id="siteTGif"  src="https://turniej-generator.pl/widget/site/' + xApiKey + '" width="100%" frameborder="0" title="Turniej-Generator" height={500} style="border:none;"></iframe>'}
                    language='html'
                    showLineNumbers={false}

                  /></Message>
                {xApiKey ?
                  <div style={{ border: "#828282 solid 1px", borderRadius: 5, padding: 0,position:"relative" }}>
                    <Label color='grey' size='mini' style={{left:2,top:2,position:"absolute"}}>
                      Podgląd:
                    </Label>

                    <iframe id="siteTGif1" src={"/widget/site/" + xApiKey} width="100%" height={500} title="Turniej-Generator" frameborder="0" style={{ border: "none" }}></iframe>
                    <a href={"/widget/site/" + xApiKey} target="_blank" rel="noopener noreferrer"><Button fluid size='tiny'>ZOBACZ STRONĘ W NOWYM OKNIE</Button></a>
                  </div>

                  : ""}
              </Grid.Column>
              <Grid.Column width={8}>
                <Message >
                  <Label color='blue' >
                    Link dedykowana strona treningi
                  </Label>
                  <CodeBlock
                    text={'<iframe id="siteTGif" src="https://turniej-generator.pl/widget/activity/' + xApiKey + '" width="100%" frameborder="0" height={500} title="Turniej-Generator treningi" style="border:none;"></iframe>'}
                    language='html'
                    showLineNumbers={false}

                  /></Message>
                {xApiKey ?
                  <div style={{ border: "#828282 solid 1px", borderRadius: 5, padding: 0,position:"relative" }}>
                    <Label color='grey' size='mini' style={{left:2,top:2,position:"absolute"}}>
                      Podgląd:
                    </Label>

                    <iframe id="siteTGif2" src={"/widget/activity/" + xApiKey} width="100%" height={500} title="Turniej-Generator treningi" frameborder="0" style={{ border: "none" }}></iframe>
                    <a href={"/widget/activity/" + xApiKey} target="_blank" rel="noopener noreferrer"><Button fluid size='tiny'>ZOBACZ STRONĘ W NOWYM OKNIE</Button></a>
                  </div>
                  : ""}
              </Grid.Column>
            </Grid.Row>
          </Grid>




          <Header as='h4'>Krok 4: Dostosuj parametry iframe</Header>
          <p>
            <List bulleted>
              <List.Item>width: Ustaw szerokość iframe w pikselach lub jako procent szerokości rodzica, np. width="100%".</List.Item>
              <List.Item>height: Ustaw wysokość na sztywno iframe w pikselach, np. height="500".</List.Item>
              <List.Item>frameborder: Ustaw frameborder="0" aby usunąć ramkę wokół iframe, lub ustaw na 1, aby ją pokazać.</List.Item>
              <List.Item>onload="iframeLoadedTG()" jezeli używasz parametru onload, który powoduje ustawienie dynamicznej wysokości, dodaj poniższy script który dynamicznie dostosowuje wielkość iframe do zawartości </List.Item>
              <Message >
                <CodeBlock
                  text={'<script type="text/javascript"> function iframeLoadedTG() { var iFrameID = document.getElementById("siteTGif");if (iFrameID) { iFrameID.height = ""; iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px"; } } </script > '}
                  language='js'
                  showLineNumbers={false}

                /></Message>


            </List>
          </p>
          <Header as='h4'>Krok 5: Zapisz zmiany</Header>
          <p>
            Zapisz zmiany w pliku HTML i prześlij plik na serwer, jeśli pracujesz lokalnie.
            Jeśli korzystasz z CMS, zmiany zostaną zapisane automatycznie po publikacji.
          </p>
          <Header as='h4'>Krok 6: Sprawdź stronę</Header>
          <p>
            Odśwież swoją stronę w przeglądarce internetowej, aby upewnić się, że iframe wyświetla osadzoną stronę poprawnie.
          </p>
          <br />
          Jeżeli masz problem z osadzeniem strony, napisz do Nas.

        </> : <Message>Brak aktywnej subskrypcji lub włączonej usługi</Message>}
      </>}
    </>
  )
}

export default WebsiteConfigurator;