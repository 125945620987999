import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Divider, Message, Table } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import useAuth from '../../../../hooks/useAuth';
import { ConnectWS } from '../../../../ConnectWS'
import { changeInitialScale, statusPayments } from '../../../../myfunctions';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import ShowPagination from '../../../../tools/ShowPagination';

function MyPayments(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);

  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const { width } = useWindowDimensions();
  const [rowData, setrowData] = useState({ current: [], filter: null, data: [], activePage: 1 });

  useEffect(() => {
    changeInitialScale(0.5)
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MojePłatności")
    }
    getUserPayments()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps



  function getUserPayments() {
    ConnectWS("/generator/getUserPayments", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        let tmparr2 = []
        response.data.forEach((row1) =>{
          if(row1.payment_status==="SUCCESS" || row1.payment_status==="PENDING"){
            tmparr2.push({...row1})
          }
      })

        setrowData({ current: tmparr2, filter: null, data: tmparr2, activePage: 1 })
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }

    });
  }



  function getFvData(data) {
    if (data) {
      try {
        if (data.nip) {
          return <div style={{ lineHeight: "12px", fontSize: 12 }}>
            <b>Nazwa firmy</b>: {data.company_name} <br />
            <b>NIP</b>: {data.nip} <br />
            <b>Adres</b>: {data.street} <br />
            <b>Miejscowość</b>: {data.place} <br />
            <b>Poczta</b>: {data.postal_code} {data.postal} <br />
            
          </div>
        } else {
          return <div style={{ lineHeight: "12px", fontSize: 12 }}>
            <b>Imie</b>: {data.name} <br />
            <b>Nazwisko</b>: {data.surname} <br />
            <b>Adres</b>: {data.street} <br />
            <b>Miejscowość</b>: {data.place} <br />
            <b>Poczta</b>: {data.postal_code} {data.postal} <br />
          </div>
        }

      } catch (e) {
        return ""
      }
    }

  }

  function getTypeP(row) {
    try {
      if (row.order_nr.includes("TOKEN")) {
        return <div title={row.order_nr}>TOKEN: {row.token_amount}</div>
      } else if (row.order_nr.includes("SUBSCRIPTION")) {
        return <div title={row.order_nr}>SUBSKRYPCJA</div>
      } else if (row.order_nr.includes("SUPPORT")) {
        return <div title={row.order_nr}>WSPARCIE TECHNICZNE</div>
      }else if (row.order_nr.includes("UPGRADE_TOURNAMENT_MEMBERS")) {
        return <div title={row.order_nr}>UPGRADE UCZESTNIKÓW W TURNIEJU</div>
      } else {
        return <div>{row.order_nr}</div>
      }
    } catch (e) {
      return "?"
    }
  }

  return (
    <>
      <Header as='h5' textAlign='center' block>
        <Icon name='payment' />
        <Header.Content>Moje płatności</Header.Content>
      </Header>
      <Divider />
      {rowData && rowData.current.length > 0 ?
        <>  <div style={{ overflowX: "auto", width: width < 700 ? width - 105 : "100%" }}>
          <Table striped compact celled unstackable size='small' color="grey">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell textAlign='center' >Data</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Usługa</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Status</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={1} singleLine>Kwota brutto</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Dane do faktury</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Numer faktury</Table.HeaderCell>

              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rowData.current.map((line3, i) => <Table.Row>
                <Table.Cell textAlign='center' width={1} singleLine>{line3.create_ts}</Table.Cell>
                <Table.Cell textAlign='center'>{getTypeP(line3)}</Table.Cell>
                <Table.Cell textAlign='center'>{statusPayments(line3.payment_status, true)}</Table.Cell>
                <Table.Cell textAlign='center'>{line3.price} PLN</Table.Cell>
                <Table.Cell>{getFvData(line3.fv_data)}</Table.Cell>
                <Table.Cell textAlign='center'>{line3.fv_nr ? line3.fv_nr : "-"}</Table.Cell>
              </Table.Row>)}
            </Table.Body>
          </Table>
        </div>
          <ShowPagination myTab={rowData} setMyTab={setrowData} rowCurrent={10} />
        </>
        :
        <Message>
          Brak płatności
        </Message>
      }
    </>
  )
}

export default MyPayments;