import React, { useEffect, useState, useContext } from 'react';
import { Segment, Icon, Header, Grid, Label, Button, Table, Modal, Image, Message } from 'semantic-ui-react'
import { ConnectWS } from '../../../../ConnectWS';
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

import { getAddressLocationGoole, getlinkplayerprofile } from '../../../../myfunctions';
import noimage from '../../../../assets/noimage.png';
import ShowImgAvatar from '../../../../tools/ShowImgAvatar';
import MyTournamentAddUsersActivity from '../MyTournaments/MyTournamentAddUsersActivity';
import { confirmAlert } from 'react-confirm-alert';
import LogoTeamLigaGenerator from '../../../../assets/logotgver1.svg';
import MyImageLazy from '../../../../tools/MyImageLazy';
import GetEditorTG from '../../../../tools/GetEditorTG';



function MyActivities(props) {
  const [activity_idT, setactivity_idT] = useState(null);
  const [rowDataJson, setrowDataJson] = useState(null);
  const [loading, setloading] = useState(true);
  const [isOpen, setisOpen] = useState(false)
  const [rte, setrte] = useState("");


  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars

  useEffect(() => {
    if (props.activity_id) {
      setactivity_idT(props.activity_id)
      getActivitie(props.activity_id)
      setisOpen(true)
    }

    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [props.activity_id, authContext.refreshView])// eslint-disable-line react-hooks/exhaustive-deps


  function getActivitie(activity_id) {
    setloading(true)

    ConnectWS("/activity/getActivity", authContext.token, { activity_id: activity_id }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });

      } else if (response && response.status && Number(response.status) === 200) {

        setrowDataJson(response.data)
        setrte(response.data.description)


      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }


      }
      setloading(false)


    });
  }

  function getValueDataInput(locked, nameInput, valueInput, typeInput, dataSendName, activity_id) {

    authContext.changeModalInfo({ nameModal: "ChangeTurnament", name: nameInput, value: valueInput, itype: typeInput, dataSendName: dataSendName, activity_id: activity_id, jsonBody: rowDataJson })
  }

  function retDefInfo() {
    return <span style={{ color: "red" }}>Brak podanych informacji</span>
  }



  function getLoadingData() {
    return <Icon name="spinner" loading />
  }

  function cancelActivityQ(activity_id, name, status) {
    setisOpen(false)
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: status === 1 ? 'Czy chcesz odwołać zajęcia ' + name : 'Czy chcesz przywrócić zajęcia ' + name,
      buttons: [
        {
          label: 'Tak',
          onClick: () => cancelActivity(activity_id, status)
        },
        {
          label: 'Nie',
          onClick: () => setisOpen(true)
        }
      ]
    });

    function cancelActivity(activity_id, status) {
      setisOpen(true)

      ConnectWS("/activity/cancelActivity", authContext.token, { activity_id: activity_id, canceled: status }, function (response, logout) {

        if (logout === 1) {

        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });


        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }

          //console.log(response);
        }
        authContext.changeRefreshView(authContext.refreshView + 1)


      });
    }




  }


  function removeUsersActivityQ(activity_id, activity_member_id, member_name) {
    setisOpen(false)
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować uczestnika ' + member_name + '?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => removeUsersActivity(activity_id, activity_member_id)
        },
        {
          label: 'Nie',
          onClick: () => setisOpen(true)
        }
      ]
    });

    function removeUsersActivity(activity_id, activity_member_id) {
      setisOpen(true)

      ConnectWS("/activity/removeMemberFromActivity", authContext.token, { activity_id: activity_id, activity_member_id: activity_member_id }, function (response, logout) {

        if (logout === 1) {

        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });

          authContext.changeRefreshView(authContext.refreshView + 1)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }

          //console.log(response);
        }



      });
    }




  }




  function closeModal() {
    setisOpen(false)
    props.setactivity_id(null)
  }

  return (
    <div style={{ marginTop: 10 }}>


      <Modal
        //onClose={() => closeModal()}
        open={isOpen}
        size='fullscreen'
      //dimmer='blurring'

      >
        <Modal.Header >
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h3' textAlign='center'>
                  <Icon name='handshake' color="red" />
                  <Header.Content>Zajęcia/treningi</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image alt="logo" floated='right' size='big' style={{ maxWidth: 200 }} centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content  style={{ backgroundColor: "#f5f5f5" }}>

          <Segment>

            <Grid stackable  >
              <Grid.Row >
                <Grid.Column width={width < 1000 ? 16 : 10} >
                  <Grid celled='internally' unstackable >
                    <Grid.Row className='mp3'>
                      <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Nazwa</b> <Icon name="info circle" /></Grid.Column>
                      <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Organizator", rowDataJson ? rowDataJson.activity_name : "?", "input", "activity_name", activity_idT)} />
                        {loading ? getLoadingData() : rowDataJson ? rowDataJson.activity_name : "?"}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='mp3'>
                      <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Początek zajęć</b> <Icon name="calendar" /></Grid.Column>
                      <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Start rozgrywek", rowDataJson ? rowDataJson.activity_start_date : "?", "datapicker", "activity_start_date", activity_idT)} />
                        {loading ? getLoadingData() : rowDataJson ? rowDataJson.activity_start_date : "?"}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='mp3'>
                      <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Koniec zajęć</b> <Icon name="calendar" /></Grid.Column>
                      <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Koniec rozgrywek", rowDataJson ? rowDataJson.activity_stop_date : "?", "datapicker", "activity_stop_date", activity_idT)} />
                        {loading ? getLoadingData() : rowDataJson ? rowDataJson.activity_stop_date : "?"}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='mp3'>
                      <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Koniec Zapisów</b> <Icon name="calendar" /></Grid.Column>
                      <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'>  <Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(1, "Koniec Zapisów", rowDataJson ? rowDataJson.register_stop_date : "?", "datapicker", "register_stop_date", activity_idT)} />
                        {loading ? getLoadingData() : rowDataJson ? rowDataJson.register_stop_date : "?"}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='mp3'>
                      <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Prowadzący</b> <Icon name="user" /></Grid.Column>
                      <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Prowadzący", rowDataJson ? rowDataJson.trainer : "?", "InputValue", "trainer", activity_idT)} />
                        {loading ? getLoadingData() : rowDataJson ? rowDataJson.trainer : "?"}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='mp3'>
                      <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Limit uczestników</b> <Icon name="users" /></Grid.Column>
                      <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Limit uczestników", rowDataJson ? rowDataJson.members_limit : "?", "InputNumber", "members_limit", activity_idT)} />
                        {loading ? getLoadingData() : rowDataJson && rowDataJson.members_limit ? rowDataJson.members_limit : retDefInfo()}
                      </Grid.Column>
                    </Grid.Row>


                    <Grid.Row className='mp3'>
                      <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Lokalizacja rozgrywek</b><Icon name="map marker" /></Grid.Column>
                      <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'>  <Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Lokalizacja", rowDataJson ? rowDataJson.place : "?", "InputPlace", "place", activity_idT)} />
                        {rowDataJson && rowDataJson.place ? getAddressLocationGoole(rowDataJson.place, false) : retDefInfo()}
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className='mp3'>
                      <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Zajęcia odwołane</b> <Icon name="users" /></Grid.Column>
                      <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => cancelActivityQ(activity_idT, rowDataJson.activity_name, rowDataJson.canceled === 0 ? 1 : 0)} />
                        {loading ? getLoadingData() : rowDataJson && rowDataJson.canceled === 0 ? <Label color='green'>NIE</Label> : <Label color='orange'>TAK</Label>}
                      </Grid.Column>
                    </Grid.Row>

                  </Grid>
                  <Message
                    size='mini'
                    header='Odwołanie zajęć'
                    content='Jeżeli zajęcia zostaną odwołane do wszystkich zapisanych uczestników zostanie wysłany email.'
                  />

                  <GetEditorTG rte={rte} setrte={setrte} />

                  <Button.Group fluid size='tiny' style={{ marginTop: 5 }}>
                    <Button color='grey' onClick={() => getValueDataInput(0, "Opis", rte, "RichTextEditor", "description", activity_idT)} >ZAPISZ ZMIANY OPISU</Button>
                    <Button.Or text='lub' />
                    <Button onClick={() => {
                      setrte("")
                      getValueDataInput(0, "Opis", "", "RichTextEditor", "description", activity_idT)
                    }
                    }>SKASUJ ZAWARTOŚĆ OPISU</Button>
                  </Button.Group>

                </Grid.Column>
                <Grid.Column width={width < 1000 ? 16 : 6} style={{ backgroundColor: width < 1000 ? "#f7f7f7" : "#f7f7f7", padding: 20 }}>
                  <Icon style={{ position: "absolute", zIndex: 100, right: 2 }} className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Plakat", rowDataJson.img_url, "input", "img_url", activity_idT)} />
                  <div className={"plakatTreningi"}  >
                    <MyImageLazy openView={true} alt="plakat" className={"plakatTreningi"} src={rowDataJson && rowDataJson.img_url ? rowDataJson.img_url : noimage} />
                  </div>
                  <Segment>
                    <Label style={{ marginBottom: 10 }} color='blue' ribbon>
                      <Icon name='add' /> Zapisz uczestnika
                    </Label>

                    {rowDataJson ?
                      <MyTournamentAddUsersActivity count_member={rowDataJson.members.length} members_limit={rowDataJson ? rowDataJson.members_limit : 0} activity_id={props.activity_id} subTurnamentType={"USER"} registrationTournament={false} />
                      : ""}
                  </Segment>

                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>




          <Segment>
            <Label style={{ marginBottom: 10 }} color='blue' ribbon>
              <Icon name='users' /> Lista uczestników
            </Label>


            <div style={{ overflowX: "auto", width: width < 700 ? "100%" : "100%" }} >

              <Table size='small' celled striped compact unstackable >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell singleLine width={1} textAlign='center'>LP</Table.HeaderCell>
                    <Table.HeaderCell >UCZESTNIK</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>EMAIL</Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign='center' singleLine>POTWIERDZENIE </Table.HeaderCell>
                    <Table.HeaderCell width={1} className='hiddenPrint'></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {rowDataJson && rowDataJson.members ?
                    rowDataJson.members.map((line2, i) =>
                      <Table.Row key={i}>
                        <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                        <Table.Cell singleLine style={{ minWidth: 250, position: "relative" }}><figure className='imgPlayer'><ShowImgAvatar urlpic={line2.member_img_file} mtype={"0"} msize={'mini'} /><figcaption>{getlinkplayerprofile(line2.member_id, line2.member_name)} </figcaption></figure> </Table.Cell>
                        <Table.Cell textAlign='center'>{line2.member_email} </Table.Cell>
                        <Table.Cell textAlign='center'>{line2.member_confirmed === 1 ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}</Table.Cell>
                        <Table.Cell className='hiddenPrint' textAlign='center' ><Icon className='cursorPointer' title="Kasowanie uczestnika" name='trash' onClick={() => removeUsersActivityQ(activity_idT, line2.activity_member_id, line2.member_name)} /></Table.Cell>
                      </Table.Row>
                    )
                    : <Table.Row></Table.Row>}

                </Table.Body>


              </Table>

            </div>

          </Segment>

        </Modal.Content>
        <Modal.Actions style={{ backgroundColor: "#e8e8e8" }}>

          <Button size="tiny" color='black' onClick={() => closeModal()}>
            ZAMKNIJ
          </Button>

        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default MyActivities;