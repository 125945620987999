import React, { useEffect, useContext, useState } from 'react';
import { Button, Grid, Header, Icon, Image, Modal, Step, StepContent, StepDescription, StepGroup, StepTitle } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../../../../ConnectWS';
import AuthContext from '../../../../context/authContext';
import LogoTeamLigaGenerator from '../../../../assets/logotgver1.svg';
import ChangeMyData from '../../Dashboard/MyAccount/ChangeMyData';
import { bruttoToNetto, loadingShow } from '../../../../myfunctions';

function BuyProductCompany(props) {

  /*
buyProductType 
1 - tokeny
2 - suby
3 - wsparcie techniczne
4 - podniesienie membersow z darmowego
  */

  const authContext = useContext(AuthContext);
  const { addToast } = useToasts();
  const [open, setopen] = useState(false);
  const [step, setstep] = useState(0)
  const [ad, setad] = useState(null);


  function preGenerateData(stype) {
    /*
    confirmAlert({
      title: 'Czy chcesz fakturę?',
      message: 'Faktura zostanie wystawiona na dane wprowadzone w systemie',
      buttons: [
        {
          label: 'Tak',
          onClick: () => generateData(stype, true)
        },
        {
          label: 'Nie',
          onClick: () => generateData(stype, false)
        }
      ]
    });
     */
    setopen(true)
  }


  function getAccountData() {
    ConnectWS("/login/getAccountData", authContext.token, {}, function (response, logout) {
      if (logout === 1) {
        addToast("Brak zalogowanego użytkownika", { appearance: 'info' });

      } else if (response && response.status && Number(response.status) === 200) {
        setad(response.data)
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }

    });
  }

  async function generateData(stype, fvat) {

    //sprwadzanie czy user ma wprowadzone dane adresowe dla faktury



    let amount = props.price;
    let isToken = props.isToken;
    let buyProductType = props.buyProductType;
    let tournament_id = props.tournament_id ? props.tournament_id : 0

    ConnectWS("/payment/createTransactionP24", authContext.token, { amount: amount, isToken: isToken, fvat: fvat, buyProductType: buyProductType, tournament_id:tournament_id }, function (response, logout) {
      if (logout === 1) {
        addToast("Brak zalogowanego użytkownika", { appearance: 'info' });

      } else if (response && response.status && Number(response.status) === 200) {
        window.location.href = response.data.link
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }

    });
  }

  useEffect(() => {
    if (step === 1) {
      getAccountData()
    }
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [step])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [props.isDemo, props.btnname], props.issmall)// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>{props.isToken ?
      <Button animated='fade' fluid color='red' size={props.issmall ? "tiny" : "medium"} disabled={false} onClick={() => props.isDemo ? addToast('Zablokowane na koncie testowym', { appearance: 'warning' }) : preGenerateData(props.stype)}>
        <Button.Content visible>
          {props.btnname}
        </Button.Content>
        <Button.Content hidden>
          DOBRA DECYZJA!
        </Button.Content>
      </Button>
      : <Button animated='fade' fluid color='violet' size={props.issmall ? "tiny" : "medium"} disabled={false} onClick={() => props.isDemo ? addToast('Zablokowane na koncie testowym', { appearance: 'warning' }) : preGenerateData(props.stype)}>
        <Button.Content visible>
          {props.btnname}
        </Button.Content>
        <Button.Content hidden>
          DOBRA DECYZJA!
        </Button.Content>
      </Button>}
      <Modal
        open={open}
        size='large'
      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='center'>
                  <Icon name='globe' color="blue" />
                  <Header.Content>Płatność</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image floated='right' size='big' alt="logo" centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content style={{ backgroundColor: "#f5f5f5" }}>
          <Modal.Description style={{ backgroundColor: "#f5f5f5" }}>
            <StepGroup fluid ordered size='mini'>
              <Step completed={step > 0 ? true : false} active={step === 0 ? true : false}>
                <StepContent>
                  <StepTitle>Dane do płatności</StepTitle>
                  <StepDescription style={{ margin: 0, padding: 0 }}>Wymagane</StepDescription>
                </StepContent>
              </Step>

              <Step completed={step > 1 ? true : false} active={step === 1 ? true : false}>
                <StepContent>
                  <StepTitle>Podsumowanie</StepTitle>
                  <StepDescription style={{ margin: 0, padding: 0 }}>Płatności</StepDescription>
                </StepContent>
              </Step>

            </StepGroup>

            {step === 0 ? <ChangeMyData paymentView={true} setstep={setstep} /> : ""}
            {step === 1 ? <> <Button fluid size='mini' onClick={() => setstep(0)}>
              WSTECZ
            </Button>

              {ad ? <div>

                <Grid stackable style={{ marginTop: 20 }}>
                  <Grid.Row divided>
                    <Grid.Column width={8} textAlign="right">

                      <b>Dane do faktury</b><br />
                      {ad.company === 0 ? <div>
                        {ad.user_name} {ad.user_surname} <br />
                        {ad.place}, {ad.street}<br />
                        {ad.postal_code}, {ad.postal}<br />
                      </div> : <div>
                        {ad.company_name}  <br />
                        {ad.nip} <br />
                        {ad.place}, {ad.street}<br />
                        {ad.postal_code}, {ad.postal}<br />
                      </div>}


                    </Grid.Column>
                    <Grid.Column width={8} textAlign="left" >
                      <b>Do zapłaty</b><br />
                      {props ? <>
                        {props.messageProduct}
                        <div style={{ lineHeight: "20px", marginTop: 10 }}>
                          <span style={{ fontSize: 13 }}>{bruttoToNetto(props.price)} PLN <span style={{ fontSize: 12 }}>netto</span></span><br />
                          <span style={{ fontSize: 22, fontWeight: "bold" }}>{props.price} PLN <span style={{ fontSize: 16 }}>brutto</span></span>

                        </div>

                      </> : ""}
                      <br />
                      <Button fluid color='blue' onClick={() => generateData(props.stype, true)}>PRZEJDŹ DO PŁATNOŚCI</Button>

                    </Grid.Column>
                  </Grid.Row>
                </Grid>





              </div> : loadingShow()}





            </> : ""}

          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {step === 0 ? <Button onClick={() => setopen(false)}>ZAMKNIJ</Button> : ""}
        </Modal.Actions>
      </Modal>
    </>

  )
}

export default BuyProductCompany;