import React, { useState, useEffect,useContext } from 'react';
import { Button, Flag, Form, Grid, Header, Icon, Image, Input, Label, Modal } from 'semantic-ui-react';
import LogoTeamLigaGenerator from '../assets/logotgver1.svg';
import { Calendar } from 'primereact/calendar';
import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../ConnectWS';
import { changeFormatDate, changeFormatDateToTime } from '../myfunctions';
import useWindowDimensions from '../hooks/useWindowDimensions';
import AuthContext from '../context/authContext';

function CallBackTG() {
    const [open, setOpen] = useState(false)
    const { addToast } = useToasts();
    const [loading, setloading] = useState(false)
    const [enabledDatesARR, setenabledDatesARR] = useState([])
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    const authContext = useContext(AuthContext);


    useEffect(() => {
       
        let tmparr = []
        for (let i = 0; i < 2; i++) {
            tmparr.push(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+i))
        }
        setenabledDatesARR(tmparr)
        setForm({ ...form
            ,dt: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
            ,dttime: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes() + 30) 
            })
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps



    const [form, setForm] = useState({
        phone: '',
        dt: '',
        dttime: '',
        name:''
    })

    function isMobile(){
        return width<600
    }

    const submitCallBack = async e => {
        e.preventDefault();

        console.log(form)

        setloading(true)

        let dt = changeFormatDate(form.dt) +" "+changeFormatDateToTime(form.dttime)
        ConnectWS("/data/addContactRequest", authContext.token, { phone:form.phone,dt:dt,contact_name:form.name }, function (response, logout) {
            if (logout === 1) {
            } else if (response && response.status && Number(response.status) === 200) {

                addToast(response.data.message, { appearance: 'success' });
                setForm({ ...form,phone:'',name:''
                    ,dt: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
                    ,dttime: new Date(new Date().getHours(), new Date().getMinutes() + 30) 
                    })
                setTimeout(() => {
                    setOpen(false)
                }, 3000);

            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }
            setloading(false)


        });
    }


    return (
        <>
            <div className='callback-widgetTG' style={{bottom:isMobile() ? 40 : 30,left:isMobile() ? 10 : 30}} onClick={() => setOpen(true)}>
                <Icon name="phone volume" style={{ fontSize: 35, marginTop: 20, marginLeft: 14, color: "#fff", rotate: "-20deg" }} />
            </div>
            <Modal
                onOpen={() => setOpen(true)}
                open={open}
                size='small'
            >
                <Modal.Header>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as='h2'  textAlign='center'>
                                    <Icon name='phone volume' color="green" style={{ rotate: "-20deg" }} />
                                    <Header.Content style={{ fontSize: isMobile() ? 13 : 18, lineHeight: "20px" }}>Skontaktuj się z naszym specjalistą</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" only='large screen'>
                                <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Modal.Header>
                <Modal.Content style={{ textAlign: "center",fontSize:isMobile() ? 12 : 15 }} className='modalBGcolor'>
                    <p>
                        Masz pytania dotyczące naszego generatora turniejów? Skorzystaj z naszego formularza kontaktowego, aby umówić się na rozmowę telefoniczną z naszym specjalistą!
                    </p>
                    <p>
                        Nasz zespół ekspertów jest gotowy, aby pomóc Ci w każdej sprawie związanej z organizacją turniejów, doradzić najlepsze rozwiązania oraz odpowiedzieć na wszelkie pytania dotyczące naszego generatora. Wypełnij poniższy formularz, a my skontaktujemy się z Tobą w dogodnym dla Ciebie terminie.
                    </p>
                    <Form size={isMobile() ? 'small' : 'small'} onSubmit={submitCallBack} style={{ marginTop: isMobile() ? 0 : 40 }} >
                        <Form.Group widths='equal'>
                        <Form.Field >
                                <label>Imię</label>

                                <Input labelPosition='left' value={form.name} required size='mini' onChange={(e,d) => setForm({ ...form, name: d.value })} >
                                <Label ><Icon name="user"/></Label>
                                <input />
                                </Input>
                          

                            </Form.Field>
                            
                            <Form.Field >
                                <label>Numer telefonu <small>Format (9 cyfr)</small></label>

                                <Input labelPosition='left' type="tel" value={form.phone} required size='mini' onChange={(e,d) => setForm({ ...form, phone: d.value })}  pattern="[0-9]{9}" >
                                    <Label ><Flag name='pl' />+48</Label>
                                    <input />
                                </Input>

                            </Form.Field>
                            </Form.Group>
                            <Form.Group widths='equal'>
                            <Form.Field >
                                <label>Preferowana data</label>

                                <Calendar enabledDates={enabledDatesARR} value={form.dt} dateFormat="yy-mm-dd" locale="pl" onChange={(e) => e.value !== null ? setForm({ ...form, dt: e.value }) : ""}  />

                            </Form.Field>
                            <Form.Field >
                                <label>Preferowana godzina</label>

                                <Calendar icon={<Icon name="user"/>} value={form.dttime} timeOnly dateFormat="yy-mm-dd" locale="pl" onChange={(e) => e.value !== null ? setForm({ ...form, dttime: e.value }) : ""} showTime hourFormat="24" />

                            </Form.Field>
                            </Form.Group>
                        <Button size={isMobile() ? 'mini' : 'small'} loading={loading} disabled={loading} style={{ marginTop: isMobile() ? 0 : 30 }} color='blue' fluid>WYŚLIJ PROŚBĘ O KONTAKT</Button>
                    </Form>

                </Modal.Content>
                <Modal.Actions>

                <Button color='grey' size={isMobile() ? 'mini' : 'small'} onClick={() => setOpen(false)}>
                        ZAMKNIJ
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default CallBackTG;