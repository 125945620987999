import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Divider, Form, Input, Button } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import useAuth from '../../../../hooks/useAuth';
import { ConnectWS } from '../../../../ConnectWS'
import { confirmAlert } from 'react-confirm-alert';
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import { changeInitialScale, checkiscompanypay } from '../../../../myfunctions';

function ChangeMyData(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars

  const isDemo = useCheckUserIsDemo()


  const [iscompanypay, setiscompanypay] = useState(null);

  const [form, setForm] = useState({
    name: '',
    surname: '',
  });

  const [formIsCompany, setFormIsCompany] = useState({
    name: '',
    surname: '',
    company: 0,
    nip: null,
    company_name: null,
    street: null,
    postal_code: null,
    place: null,
    postal: null,
  });

  function getAccountData() {
    ConnectWS("login/getAccountData", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
        authContext.changeShowOther(false)
      } else if (response && response.status && Number(response.status) === 200) {
        setForm({ ...form, name: response.data.user_name, surname: response.data.user_surname })
        setFormIsCompany({ ...response.data, name: response.data.user_name, surname: response.data.user_surname })

      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }



    });
  }


  useEffect(() => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: ChangeMyData")
    }
    setiscompanypay(checkiscompanypay())
    changeInitialScale(0.5)
    getAccountData()

    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps




  const submitModal = e => {
    e.preventDefault();

    setloading(true)

    ConnectWS("login/editAccountData", authContext.token, iscompanypay ? { ...formIsCompany } : { ...form }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {


        if (props.paymentView && props.setstep) {
          props.setstep(1)
        } else {
          addToast(response.data.message, { appearance: 'success' });
        }

      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloading(false)


    });


  }

  function delAccount() {
    confirmAlert({
      title: 'Czy chcesz usunąć swoje konto',
      message: 'Proszę potwierdzić',
      buttons: [
        {
          label: 'Tak',
          onClick: () => delAccountA()
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function delAccountA() {
      ConnectWS("login/delAccountAndPersonalData", authContext.token, {}, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);
          authContext.changeShowOther(false)
        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          setTimeout(() => {
            addToast('Zaraz nastapi wylogowanie', { appearance: 'success' });
          }, 1000);
          setTimeout(() => {
            setAuth(false);
          }, 3000);
        } else {
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }

        }
      });
    }
  }

  return (
    <div style={{ backgroundColor: "#f5f5f5", padding: 10 }}> {props.paymentView ? "" : <> <Header as='h5' textAlign='center' block>
      <Icon name='edit' />
      <Header.Content>Moje dane</Header.Content>
    </Header>
      <Divider />
    </>}

      {iscompanypay ? <Form onSubmit={submitModal} size='tiny'>
        <Form.Field
          control={Input}
          required
          label='Imię'
          placeholder='Imię'
          value={formIsCompany.name}
          onChange={(e, data) => setFormIsCompany({ ...formIsCompany, name: data.value })}
        />
        <Form.Field
          control={Input}
          required
          label='Nazwisko'
          placeholder='Nazwisko'
          value={formIsCompany.surname}
          onChange={(e, data) => setFormIsCompany({ ...formIsCompany, surname: data.value })}
        />
        <Divider horizontal>DANE DO FAKTURY</Divider>
        <Form.Group inline>
          <Form.Radio
            label='Osoba prywatna'
            value={false}
            checked={formIsCompany.company === 1 ? false : true}
            onChange={(e, data) => setFormIsCompany({ ...formIsCompany, company: 0 })}
          />
          <Form.Radio
            label='Firma'
            value={true}
            checked={formIsCompany.company === 1 ? true : false}
            onChange={(e, data) => setFormIsCompany({ ...formIsCompany, company: 1 })}
          />

        </Form.Group>
        {formIsCompany.company ? <>
          <Form.Field
            control={Input}
            required
            label='Nazwa firmy'
            placeholder='Nazwa firmy'
            value={formIsCompany.company_name}
            onChange={(e, data) => setFormIsCompany({ ...formIsCompany, company_name: data.value })}
          />
          <Form.Field
            control={Input}
            required
            label='NIP'
            type="number"
            placeholder='NIP'
            value={formIsCompany.nip}
            onChange={(e, data) => setFormIsCompany({ ...formIsCompany, nip: data.value })}
          />
        </> : ""}



        <Form.Field
          control={Input}
          required
          label='Ulica i numer'
          placeholder='Ulica i numer'
          value={formIsCompany.street}
          onChange={(e, data) => setFormIsCompany({ ...formIsCompany, street: data.value })}
        />
        <Form.Field
          control={Input}
          required
          label='Miejscowość'
          placeholder='Miejscowość'
          value={formIsCompany.place}
          onChange={(e, data) => setFormIsCompany({ ...formIsCompany, place: data.value })}
        />
        <Form.Group >
          <Form.Field
            control={Input}
            required
            width={4}
            pattern="^\d{2}-\d{3}$"
            label='Kod pocztowy (xx-xxx)'
            placeholder='Kod pocztowy'
            value={formIsCompany.postal_code}
            onChange={(e, data) => setFormIsCompany({ ...formIsCompany, postal_code: data.value })}
          />
          <Form.Field
            control={Input}
            required
            width={12}
            label='Poczta'
            placeholder='Poczta'
            value={formIsCompany.postal}
            onChange={(e, data) => setFormIsCompany({ ...formIsCompany, postal: data.value })}
          />
        </Form.Group>

        <Button size='tiny' color='google plus' fluid loading={loading ? true : false} disabled={loading ? true : false || isDemo ? true : false} >{props.paymentView ? "PRZEJDŹ DALEJ" : "AKTUALIZUJ DANE"}</Button>
      </Form>
        : <Form onSubmit={submitModal} size='tiny'>


          <Form.Field
            control={Input}
            required
            label='Imię'
            placeholder='Imię'
            value={form.name}
            onChange={(e, data) => setForm({ ...form, name: data.value })}
          />
          <Form.Field
            control={Input}
            required
            label='Nazwisko'
            placeholder='Nazwisko'
            value={form.surname}
            onChange={(e, data) => setForm({ ...form, surname: data.value })}
          />


          <Button size='tiny' color='google plus' fluid loading={loading ? true : false} disabled={loading ? true : false || isDemo ? true : false} > {props.paymentView ? "PRZEJDŹ DALEJ" : "AKTUALIZUJ DANE"}</Button>
        </Form>}

      {props.paymentView ? "" : <>
        <Divider />
        <Button icon labelPosition='left' size='tiny' disabled={isDemo} onClick={() => delAccount()} ><Icon name='trash' />USUŃ KONTO</Button>
      </>}
    </div>
  )
}

export default ChangeMyData;