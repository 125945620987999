import React, { useState, useEffect, } from 'react';
import { Button, Dropdown, Icon, Label, Message, Segment, Table } from 'semantic-ui-react'
import { getInfroMatchPlace2, matchplacetmpS } from '../../../myfunctions';
import { useCookies } from 'react-cookie';


function CurrentDuelsPlayingGroups(propsRoot) {
    const [cookies, setCookie] = useCookies(['MP' + propsRoot.subtournament_id]);
    const [matchplacetmp, setmatchplacetmp] = useState(0)

    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps

   
        if (cookies['MP' + propsRoot.subtournament_id]) {
            setmatchplacetmp(Number(cookies['MP' + propsRoot.subtournament_id]))
        } else {
            createCookieTG(0)
        }

        return () => {

        }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.tournamentGroups])// eslint-disable-line react-hooks/exhaustive-deps



    function createCookieTG(val) {
        if (propsRoot.subtournament_id) {
            let expiresdate = new Date();
            let today = new Date();
            expiresdate.setDate(today.getDate() + 30);
            setCookie('MP' + propsRoot.subtournament_id, val, { path: '/', expires: expiresdate });
        }

    }




    function CheckFreePlace() {
        try {
        
            if (matchplacetmp > 0 && propsRoot.tournamentGroups.length > 0) {
                let arrCurrent = []
                let arrFree = []
                propsRoot.tournamentGroups.forEach(element => {
                    element.matches.forEach(element1 => {
                        if (element1.match_place !== null && element1.member1_score === null && element1.member2_score === null) {
                            arrCurrent.push(Number(JSON.parse(element1.match_place).place_id))
                        }
                    });
                });
                for (let i = 0; i < matchplacetmp; i++) {
                    arrFree.push(Number(i) + 1)
                }
                let difference = arrFree.filter(x => !arrCurrent.includes(x));
                return difference.toString()
            }
            return ""
        } catch (e) {
            return ""
        }
    }


    return (
        <div>

            {propsRoot.tournamentGroups && propsRoot.tournamentGroups.length > 0 ?
                <>
                    <Segment className='hiddenPrint' style={{ marginBottom: 10, marginTop: 10, backgroundColor: "#eeeeee" }}>
                        <Label color='blue' ribbon style={{ marginBottom: 10 }}>
                            <Icon name="winner" /> Tabela pomocnicza
                        </Label>
                        <Icon name='tag' />
                        Ilość miejsc do rozegrania pojedynków: {' '}
                        <Dropdown
                            header='Np: nr stołu, nr kortu itp'
                            inline
                            scrolling
                            search
                            size='mini'
                            value={matchplacetmp}
                            onChange={(e, d) => {
                                setmatchplacetmp(d.value)
                                createCookieTG(Number(d.value))
                            }}
                            options={matchplacetmpS}
                        />


                        <div style={{ overflowX: "auto", width: "100%" }} >
                            {matchplacetmp === 0 ? <Message size='mini'> Proszę zdefiniować ilość miejsc do rozgrywania pojedynków</Message> :
                                <>
                                    <Message size='mini'
                                        header='Aktualne pojedynki'
                                    />

                                    <Table singleLine size='small' celled striped compact unstackable >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={1} textAlign='center'>Grupa</Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='center' style={{lineHeight:"12px"}}>Miejsce<br/><span style={{fontSize:10}}>pojedynku</span></Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>Pojedynek</Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {propsRoot.tournamentGroups.map((row, i) =>
                                                row.matches.length > 0 ? row.matches.map((line4, i) =>
                                                    line4.match_place !== null && line4.member1_score === null && line4.member2_score === null ?
                                                        <Table.Row>
                                                            <Table.Cell width={1} textAlign='center'>
                                                                {row.group_nr}
                                                            </Table.Cell>
                                                            <Table.Cell width={1} textAlign='center' style={{ position: "relative" }}>
                                                                {getInfroMatchPlace2(line4.match_place)}
                                                              
                                                            </Table.Cell>
                                                            <Table.Cell textAlign='center' style={{ position: "relative" }}>

                                                                <b>{line4.member1_name}</b> vs <b>{line4.member2_name}</b>
                                                            </Table.Cell>
                                                            <Table.Cell width={1} textAlign='center'>
                                                                <Button size='mini' color='orange' fluid onClick={() => propsRoot.setopenModalScore({ subtournament_group_member1_id: line4.subtournament_group_member1_id, subtournament_group_member2_id: line4.subtournament_group_member2_id, subtournament_group_match_id: line4.subtournament_group_match_id, member1_score: line4.member1_score, member2_score: line4.member2_score, member1_name: line4.member1_name, member2_name: line4.member2_name, match_details: line4.match_details, match_place: line4.match_place })}>Wprowadź wynik</Button>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        : ""
                                                ) : ""
                                            )}
                                        </Table.Body>
                                    </Table>
                                    <Message size='mini'
                                        header={'Wolne miejsca do gry - nr: ' + CheckFreePlace()}
                                    />

                                </>
                            }

                        </div>
                    </Segment>
                </>
                :
                ""
            }
        </div>

    )
}

export default CurrentDuelsPlayingGroups;