import React, { useEffect, useContext, useState } from 'react';
import { Button, Form, Grid, Header, Icon, Image, Input,  Message, Modal } from 'semantic-ui-react';
import AuthContext from '../context/authContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
import {  noSubIsActive} from '../myfunctions';
import { ConnectWS } from '../ConnectWS';
import { useToasts } from 'react-toast-notifications';

import LogoTeamLigaGenerator from '../assets/logotgver1.svg';
import useCheckUserIsDemo from '../hooks/useCheckUserIsDemo';
import { useTranslation } from 'react-i18next';

function EditLeagueMatchInfo(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [open, setOpen] = useState(false)
  const [match_info, setmatch_info] = useState(null)
  const [loading, setloading] = useState(false)
  const isDemo = useCheckUserIsDemo()
  const { t } = useTranslation();
  const [isAS, setisAS] = useState(false)


  
  function checkSubIsActiv() {
    try {
      if(isDemo){
        setisAS(true)
      }else if (authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended >= 0 && authContext.user.subscription_info.subscription_active) {
        setisAS(true)
      } else {
        setisAS(false)
      }
    } catch (e) {
      setisAS(false)
    }
  }

  useEffect(() => {
   
    if (props.openModalmatch_info.open) {
      checkSubIsActiv()
      setOpen(props.openModalmatch_info.open)
      if (props.openModalmatch_info.row.match_info) {
        try {
          setmatch_info(JSON.parse(JSON.stringify(props.openModalmatch_info.row.match_info)))
        } catch (e) {

        }

      }

    } else {
      setOpen(props.openModalmatch_info.open)

    }
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [props.openModalmatch_info])// eslint-disable-line react-hooks/exhaustive-deps


  function exitModal() {

    props.setopenModalmatch_info({ open: false, row: null })
    setOpen(false)
  }

  function updateJsonData(value, key) {
    let jsonTmp = { ...match_info }
    jsonTmp[key].value = value
    setmatch_info({ ...jsonTmp })
  }

  function getmatch_info(match_info) {
    try {
      const rows = [];
      for (const [key] of Object.entries(match_info).sort((a, b) => a[1].sort - b[1].sort)) {
        rows.push(
          <Form.Field
            control={Input}
            type={match_info[key].type === "inputnumber" ? "number" : match_info[key].type === "inputurl" ? "url" : "text"}
            value={match_info[key].value}
            label={match_info[key].type === "inputurl" || match_info[key].type === "inputvideourl" ? t("stats."+match_info[key].name) + " (link)" : t("stats."+match_info[key].name)}
            onChange={(e, d) => updateJsonData(d.value, key)}
            placeholder={t("stats."+match_info[key].name)}
          />
        )
      }
      return rows;
    } catch (e) {
      console.log(e)
    }
  }

  function saveDataMI(e) {
    e.preventDefault();
    let match_id = props.openModalmatch_info.row.subtournament_league_match_id
    setloading(true)
    ConnectWS("/generator/setMatchInfo", authContext.token, { match_id: match_id, match_info: { ...match_info } }, function (response, logout) {
      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
      setloading(false)

    });

  }

  return (


    <Modal
     
      open={open}
      centered={width < 900 ? false : true}
  
      size='large'


    >
      <Modal.Header>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={12} verticalAlign="middle">
              <Header as='h2' textAlign='left'>
                <Icon name='edit' color="red" />
                
                <Header.Content>Edycja informacji
                    <Header.Subheader>
                    Pojedynek: {open ? <>{props.openModalmatch_info.row.member1_name} vs {props.openModalmatch_info.row.member2_name}</> : ""}
                    </Header.Subheader>
                  </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" only='large screen'>
              <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Modal.Header>
      <Modal.Content style={{ backgroundColor: "#e0e1e2" }} scrolling>
        <Modal.Description >
          {open && match_info ?
            <Form size='mini' onSubmit={saveDataMI}>
              {getmatch_info(match_info)}
              {isAS ?
                <Button size='mini' fluid color='blue' loading={loading} disabled={loading} type='submit'>ZAPISZ</Button>
                : <>
                  <Button size='mini' fluid color='blue' disabled={true} >ZAPISZ</Button>
                </>}
            </Form>
            : ""}
          {isAS ? "" : noSubIsActive()}
          <Message size='mini' info>
            Potrzebujesz dodać inne informacje? Nie ma problemu! Napisz do Nas <a href={"mailto:biuro@turniej-generator.pl"} className="linktl" target="_blank" rel="noopener noreferrer">biuro@turniej-generator.pl</a>
          </Message>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button size="tiny" labelPosition='right' icon='close' color='grey' onClick={() => exitModal()} content="WYJDŹ" />
      </Modal.Actions>
    </Modal>



  )
}

export default EditLeagueMatchInfo;