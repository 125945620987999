import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button, Divider, Form, Grid, Header, Icon, Image, Input, Message, Modal, Segment } from 'semantic-ui-react';
import LogoTeamLigaGenerator from '../assets/logotgver1.svg';
import { Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { ConnectWS, ConnectWSSendData } from '../ConnectWS';
import Resizer from "react-image-file-resizer";
import AuthContext from '../context/authContext';
import { useToasts } from 'react-toast-notifications';

function EditImgAvatar({ memberData, playerid, forceOpen }) {
    const { addToast } = useToasts();
    const [open, setOpen] = useState(false)
    const [preopen, setpreOpen] = useState(false)
    const [image, setImage] = useState("");
    const [cropper, setCropper] = useState();
    const [cropData, setCropData] = useState("#");
    const authContext = useContext(AuthContext);

    const [defPic, setdefPic] = useState(false);

    const [member_id, setmember_id] = useState(null);
    const [loadingACC, setloadingACC] = useState(false);
    let defaultSrcUser = process.env.REACT_APP_PUBLIC_URL + '/img/nouser.png'

    const [blockb, setblockb] = useState(false);

    useEffect(() => {
        if(forceOpen){
            setOpen(true)
        }
        if (playerid) {
            setmember_id(Number(playerid))
        }

        if (memberData && memberData.img_file) {
            fetch(memberData.img_file)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], "Zdjecie", { type: "image/png" })
                    const reader = new FileReader();
                    reader.onload = () => {
                        setImage(reader.result);
                        setdefPic(false)
                        setTimeout(() => {
                            getCropData()
                        }, 100);
                    };
                    reader.readAsDataURL(file);
                })
        }



       
        setImage(defaultSrcUser)
        
        setTimeout(() => {
            getCropData()
        }, 100);
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [memberData, playerid])// eslint-disable-line react-hooks/exhaustive-deps

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const imageInputRef = useRef();
    const onChange = async (e) => {
        e.preventDefault();
        let files;
        setdefPic(false)

        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (files.length === 0) {
            setImage(defaultSrcUser);
        } else {
            const image = await resizeFile(files[0]);
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(image);
        }

    };


    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            try {
                setCropData(cropper.getCroppedCanvas().toDataURL());
                //authContext.changeDataPic(cropper.getCroppedCanvas().toDataURL())
            } catch (e) {
                // authContext.changeDataPic(null)
                console.log(e)
            }

        }
    };


    function ClearData() {

        setImage(defaultSrcUser)
        setdefPic(true)

        if (imageInputRef.current) {
            imageInputRef.current.value = "";
        }
        setTimeout(() => {
            getCropData()
        }, 100);


    }


    // przygotowanie do zmiany
    const [loading, setloading] = useState(false);
    const [loadingT, setloadingT] = useState(false);
    const [form, setForm] = useState({
        member_email: '',
        error: null,
        ok: 0,
        avatar_token: null,
        errorACC: null,
        okACC: 0,
        errorSave: null,
        okSave: 0,
    });

    function calendarFormat() {
        setForm({
            ...form,
            member_email: '',
            error: null,
            ok: 0,
            // avatar_token: null,
            errorACC: null,
            okACC: 0,
            errorSave: null,
            okSave: 0,
        })

    }

    const submit = e => {
        e.preventDefault();
        setloading(true)
        ConnectWS("generator/generateTokenForChangeMemberAvatar", null, { member_id: member_id, member_email: form.member_email }, function (response, logout) {

            if (logout === 1) {
                setForm({ ...form, error: "Bład autoryzacji", ok: 0 })
            } else if (response && response.status && Number(response.status) === 200) {
                setForm({ ...form, error: null, ok: 1 })
                setblockb(true)
            } else {
                setForm({ ...form, error: response, ok: 0 })
                console.log(response);
            }
            setloading(false)


        });

    }
    const submitAcc = e => {
        e.preventDefault();
        setloadingT(true)
        ConnectWS("generator/verifyMemberAvatarToken", null, { member_id: member_id, change_avatar_token: form.avatar_token }, function (response, logout) {

            if (logout === 1) {
                setForm({ ...form, error: "Bład autoryzacji", ok: 0 })
            } else if (response && response.status && Number(response.status) === 200) {
                setpreOpen(false)
                setOpen(true)
            } else {
                setForm({ ...form, errorACC: response, okACC: 0 })
                console.log(response);
            }
            setloadingT(false)


        });

    }

    function savePic() {
        setloadingACC(true)
        getCropData()
        if (typeof cropper !== "undefined") {

        } else {

        }
        const formData = new FormData();
        const url = cropper.getCroppedCanvas().toDataURL();
        let file
        fetch(url, file)
            .then(res => res.blob())
            .then(blob => {
                file = new File([blob], "Zdjecie", { type: "image/png" })
                formData.append('pictureData', file);
                formData.append('editPicture', defPic ? 3 : 2); //2 ok 3 kasowanie
                formData.append('member_id', member_id);
                formData.append('change_avatar_token', form.avatar_token);
                if(forceOpen){
                    ConnectWSSendData("generator/changeMemberAvatarByOrganizer", authContext.token, formData, function (response, logout) {

                        if (logout === 1) {
    
                            setForm({ ...form, errorSave: "Bład autoryzacji", okSave: 0 })
                        } else if (response && response.status && Number(response.status) === 200) {
                            setForm({ ...form, errorSave: null, okSave: 1 })
                            setOpen(false)
                            authContext.changeRefreshView(authContext.refreshView + 1)
    
                        } else {
                            setForm({ ...form, errorSave: response, okSave: 0 })
                        }
                        setloadingACC(false)
    
    
                    });
                }else{
                    ConnectWSSendData("generator/changeMemberAvatar", null, formData, function (response, logout) {

                        if (logout === 1) {
    
                            setForm({ ...form, errorSave: "Bład autoryzacji", okSave: 0 })
                        } else if (response && response.status && Number(response.status) === 200) {
                            setForm({ ...form, errorSave: null, okSave: 1 })
                            setOpen(false)
                            authContext.changeRefreshView(authContext.refreshView + 1)
    
                        } else {
                            setForm({ ...form, errorSave: response, okSave: 0 })
                        }
                        setloadingACC(false)
    
    
                    });
                }
               
            })



    }


    function showInfoNoMail(){
        addToast("Brak adresu e-mail, zapisz się do kolejnego turnieju podajac adres e-mail lub skontaktuj się z organizatorem turnieju w celu jego dodania.", { appearance: 'warning' });
    }



    return (
        <>
            <Modal
                onClose={() => ""}
                onOpen={() => setOpen(true)}
                open={open}
                dimmer='blurring'
            >
                <Modal.Header>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as='h2' textAlign='center'>
                                    <Icon name='edit' color="orange" />
                                    <Header.Content>Zmiana avatar-a</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" only='large screen'>
                                <Image floated='right' size='big' alt="logo" centered src={LogoTeamLigaGenerator} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>
                <Modal.Content >

                    <Modal.Description>
                        <Grid columns={2} divided verticalAlign='middle' style={{ marginBottom: 1 }} stackable>
                            <Grid.Row stretched>
                                <Grid.Column width={12}>
                                    <Segment>
                                        <Message size='mini'
                                            info
                                            content="Zalecany rozmiar pliku 300x300[px]"
                                        />
                                        <label className="custom-file-upload">
                                            <input type="file" accept="image/*" className="custom-file-upload" ref={imageInputRef} onChange={onChange} />
                                            <Icon name="upload" /> Wybierz plik
                                        </label>
                                        <Cropper
                                            style={{ height: 300, width: "100%" }}
                                            initialAspectRatio={1}
                                            preview=".img-preview"
                                            src={image}
                                            viewMode={1}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                            onInitialized={(instance) => {
                                                setCropper(instance);
                                            }}
                                            guides={true}
                                        />

                                    </Segment>
                                    {form.errorSave ?
                                        <Message size='mini' negative>
                                            <Message.Header>Błąd</Message.Header>
                                            <p>{form.errorSave}</p>
                                        </Message>
                                        : ""}

                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Segment>
                                        <Button fluid onClick={() => ClearData()}><span style={{ fontSize: 12 }}>PRZYWRÓĆ DOMYŚLNE ZDJĘCIE</span></Button>
                                    </Segment>
                                    <Segment>
                                        <div style={{ position: "relative", width: "120px", height: "120px", margin: "auto" }}>
                                            {cropData!=="#" ? <Image alt="logo" src={cropData} circular className="imgProfile2" style={{ margin: "auto",width: "120px", height: "120px", border: '1px solid rgb(115, 115, 115)' }} ></Image>  : ""}
                                            </div>
                                    </Segment>
                                    <Segment>
                                        <Button color='red' fluid onClick={getCropData}><span style={{ fontSize: 12 }}>ZOBACZ PODGLĄD</span></Button>
                                    </Segment>

                                </Grid.Column>
                            </Grid.Row>


                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => {
                        setOpen(false)
                        calendarFormat()
                    }}>
                        ZAMKNIJ
                    </Button>
                    <Button
                        content="ZAPISZ"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => savePic()}
                        loading={loadingACC}
                        disabled={loadingACC}
                        positive
                    />
                </Modal.Actions>
            </Modal>
            <Modal
                onClose={() => ""}
                onOpen={() => setpreOpen(true)}
                open={preopen}
                dimmer='blurring'
            >
                <Modal.Header>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as='h2' textAlign='center'>
                                    <Icon name='edit' color="orange" />
                                    <Header.Content>Zmiana avatar-a</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" only='large screen'>
                                <Image floated='right' size='big' centered src={LogoTeamLigaGenerator} alt="logo"/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>
                <Modal.Content >
                    <Modal.Description>

                        <Form onSubmit={submit} size='mini' >
                            <Form.Field
                                control={Input}
                                required
                                type="email"
                                label='E-mail'
                                placeholder='E-mail'
                                value={form.member_email}
                                onChange={(e, data) => setForm({ ...form, member_email: data.value })}
                            />
                            <Button size='mini' style={{ marginTop: 5, marginBottom: 5 }} color='blue' fluid loading={loading ? true : false} disabled={loading || blockb} >WYŚLIJ KOD WERYFIKACYJNY</Button>
                        </Form>

                        {form.error ?<>
                            <Message size='mini' negative>
                                <Message.Header>Błąd</Message.Header>
                                <p>{form.error}</p>
                            </Message>
                            <Message size='mini' info>
                                <Message.Header>Informacja</Message.Header>
                                <p>Jeżeli to Twój profil a nie wiesz jaki mail podałeś, zapraszamy do kontaktu <a href="mailto:biuro@turniej-generator.pl">biuro@turniej-generator.pl</a></p>
                            </Message>
                            </>
                            : ""}

                        <Divider />


                        <>
                            {form.ok === 1 ?
                                <Message size='mini' positive>
                                    <Message.Header>Mail został wysłany</Message.Header>
                                    <p>
                                        Proszę sprawdzić skrzynkę...
                                    </p>
                                </Message>
                                : ""
                            }
                            <Form onSubmit={submitAcc} size='mini' >
                                <Form.Field
                                    control={Input}
                                    required
                                    label='Kod weryfikacyjny'
                                    placeholder='Kod weryfikacyjny'
                                    value={form.avatar_token}
                                    onChange={(e, data) => setForm({ ...form, avatar_token: data.value })}
                                />
                                <Button size='mini' style={{ marginTop: 5, marginBottom: 5 }} color='google plus' fluid loading={loadingT ? true : false} disabled={loadingT} >POTWIERDŹ</Button>
                            </Form>
                            {form.errorACC ?
                                <Message size='mini' negative>
                                    <Message.Header>Błąd</Message.Header>
                                    <p>{form.errorACC}</p>
                                </Message>
                                : ""}
                        </>

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => {
                        setpreOpen(false)
                        calendarFormat()
                    }}>
                        ZAMKNIJ
                    </Button>
                </Modal.Actions>
            </Modal>
            {forceOpen ? "" : <Icon className='cursorPointer' name="pencil" onClick={() => memberData && memberData.playerE ? setpreOpen(true) : showInfoNoMail()} style={{ position: "absolute", bottom: 5, marginLeft: 45 }} color={memberData && memberData.playerE ? 'green' : "orange"} circular inverted />}
            

        </>
    )
}

export default EditImgAvatar;