import React, { useState, useEffect, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Icon, Header, Step, Button, Segment, Select, Form, Message, Modal, Grid, StepGroup } from 'semantic-ui-react'
import { ConnectWS } from '../../../../ConnectWS';
import AuthContext from '../../../../context/authContext';
import BracketGen from '../../Bracket/BracketGen';
import BracketGroups from '../../Bracket/BracketGroups';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import axios from '../../../../axios';
import { getIconTournamentType } from '../../../../myfunctions';

const q = [
  { id: 1, qname: "Krok 1", qdisc: "Wybierz rodzaj turnieju", qtype: 1, status: "disabled", value: "" },
  // { id: 2, qname: "Krok 2", qdisc: "Rozpocznij generowanie turnieju", qtype: 1, status: "disabled", value: "" },
  { id: 2, qname: "Krok 2", qdisc: "Rozstaw uczestników", qtype: 1, status: "disabled", value: "" },
  { id: 3, qname: "Krok 4", qdisc: "Zatwierdź wygenerowany turniej", qtype: 1, status: "disabled", value: "" },
]
let qclone = q.slice(0);

function StepCreateTournamentGroups(props) {
  const [TournamentType, setTournamentType] = useState([]);
  const [TournamentTypeSelected, setTournamentTypeSelected] = useState({});// eslint-disable-line no-unused-vars
  const [TournamentTypeSelectName, setTournamentTypeSelectName] = useState(null);
  const [open, setOpen] = React.useState(false)// eslint-disable-line no-unused-vars
  const [open2, setOpen2] = useState(false)
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [step, setstep] = useState(0)
  const [ttype, setttype] = useState(1)
  const [groupCount, setgroupCount] = useState(0)
  const [questions, setquestions] = useState(qclone)// eslint-disable-line no-unused-vars

  const [loading, setloading] = useState(false)
  const [spacingTournamentParticipantsStatus, setspacingTournamentParticipantsStatus] = useState(false)
  const [spacingTournamentParticipantsStatusError, setspacingTournamentParticipantsStatusError] = useState(false)

  const [positioningofplayer, setpositioningofplayer] = useState(false)


  const [loading2, setloading2] = useState(false)


  const [placeint, setplaceint] = useState([])
  const [form, setForm] = useState({
    tournamentTypeGames: "",
    tournamentCreateUsers: false,
    tournamentAcc: false,
  });



  function getTournamentType() {
    setloading(true)
    ConnectWS("/generator/getSubtournamentTypes", null, {}, function (response, logout) {
      if (logout === 1) {
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        //dictemat.push({ key: -1, text: "Wybierz rodzaj turnieju", value: 0 })
        //setForm({ ...form, tournamentTypeGames: 0 })
        response.data.forEach((data, i) => {
          if (data.id === 'CUP_SIMPLE' || data.id === 'CUP_DOUBLE_ELIMINATION' || data.id === 'CUP_N_ELIMINATION_SIMPLE' || data.id === 'CUP_N_ELIMINATION_DOUBLE' || data.id === 'CUP_BRASIL' || data.id === 'CUP_N_PLACE') {
            //if (1===1) {
            dictemat.push({ key: i, text: data.variant ? data.name + " Wariant:" + data.variant : data.name, value: JSON.stringify({ id: data.id, name: data.name, variant: data.variant, type: data.type }) })
          }
          //dictemat.push({ key: i, text: data.Login, value: data.Login })
        })

        setTournamentType(dictemat);

      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
      setloading(false)
    });
  }


  useEffect(() => {
    if (props.members) {
      if (props.members < 9) {
        setplaceint([{ key: '8', text: '8', value: 8 }])
      } else if (props.members < 17) {
        setplaceint([{ key: '8', text: '8', value: 8 }, { key: '16', text: '16', value: 16 }])
      } else if (props.members < 33) { 
        setplaceint([{ key: '8', text: '8', value: 8 }, { key: '16', text: '16', value: 16 }, { key: '32', text: '32', value: 32 }])
      } else {
        setplaceint([{ key: '8', text: '8', value: 8 }, { key: '16', text: '16', value: 16 }, { key: '32', text: '32', value: 32 }, { key: '64', text: '64', value: 64 }])
      }
    }

    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [props.members])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setspacingTournamentParticipantsStatus(false)
    getTournamentType()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  function nexStep(val) {

    if (step + val < 0) {

      return
    } else if (questions.length - 1 < step + val) {

      return
    }

    if (step + val === 0) {
      setgroupCount(0)
      setpositioningofplayer(false)
    }
    setspacingTournamentParticipantsStatus(false)
    setstep(step + val)

  }


  function clickPositioningofplayer(){
    addToast("Proszę teraz rozstawić uczestników", { appearance: 'info' });
    setpositioningofplayer(true)
  }


  function createSubtournamentStruct(subtournament_id, value, variant, ttype) {

    setloading(true)
    ConnectWS("/generator/createSubtournamentStruct", authContext.token, { subtournament_id: subtournament_id, subtournament_type: value, subtournament_variant: variant, groupCount: groupCount }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        questions[0] = { ...questions[0], status: "completed" }
        questions[1] = { ...questions[1], status: "disabled" }
        questions[2] = { ...questions[2], status: "disabled" }
        addToast(response.data.message, { appearance: 'success' });
        setForm({ ...form, tournamentCreate: true, tournamentTypeGames: value, tournamentCreateUsers: false, tournamentAcc: false })
        authContext.changeRefreshView(authContext.refreshView + 1)
        nexStep(+1)
      } else {
        setForm({ ...form,tournamentCreate: false, tournamentTypeGames: value })
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //cons

      }
      setloading(false)

    });





  }

  function changeTournamentType(data) {

    if (JSON.parse(data.value).type === 1 && groupCount === 0 && (JSON.parse(data.value).id === "CUP_N_ELIMINATION_SIMPLE" || JSON.parse(data.value).id === "CUP_N_ELIMINATION_DOUBLE")) {
      //CUP_N_ELIMINATION
      setTournamentTypeSelected(data)
      setOpen2(true)
      return
    }
    if (JSON.parse(data.value).type === 2 && groupCount === 0) {
      setTournamentTypeSelected(data)
      setOpen(true)
      return
    }
    if (data.value !== 0) {
      setTournamentTypeSelectName(JSON.parse(data.value).variant ? JSON.parse(data.value).name + " Wariant:" + JSON.parse(data.value).variant : JSON.parse(data.value).name)
      createSubtournamentStruct(props.subtournament_id, JSON.parse(data.value).id, JSON.parse(data.value).variant, JSON.parse(data.value).type)
      setttype(JSON.parse(data.value).type)
    }


  }

  function accRostawianieUczestników(subtournament_id, ttype) {
    setloading(true)
    let m = ttype === 1 ? "/generator/drawMissingMatchPairs" : "/generator/drawMissingGroupsMembers"
    ConnectWS(m, authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        questions[1] = { ...questions[1], status: "completed" }
        addToast(response.data.message, { appearance: 'success' });
        setForm({ ...form, tournamentCreateUsers: true })
        authContext.changeRefreshView(authContext.refreshView + 1)
        nexStep(+1)
      } else {
        setForm({ ...form, tournamentCreateUsers: true })
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //cons

      }
      setloading(false)

    });

  }


  function accTournament(subtournament_id) {
    if (form.tournamentCreateUsers === false) {
      addToast("Proszę zatwierdzić rozstawienie uczestników w Kroku 2", { appearance: 'warning' });
      return
    }
    setloading(true)
    ConnectWS("/generator/confirmSubtournamentStruct ", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        questions[2] = { ...questions[2], status: "completed" }
        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //cons

      }
      setloading(false)

    });
  }

  async function spacingTournamentParticipants(jsonB, subtournament_id, ttype) {
   
    setloading2(true)
    try {
      if (spacingTournamentParticipantsStatus) {
        addToast("Uczestnicy zostali rozstawieni już wcześniej", { appearance: 'info' });
        setloading2(false)
        return
      }
      setspacingTournamentParticipantsStatusError(false)

      let tokendata1 = "Bearer " + authContext.token;
      axios.defaults.headers.common['Authorization'] = tokendata1;

      /*
      //czyszczenie uczestników
      nie uda się po mam juz pobranego jsona
      await axios.post("/generator/removeMembersFromFirstCupRound", { subtournament_id: subtournament_id }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      */
     
      //pobieranie uczestników nieprzypisanych 
      const res = await axios.post("/generator/getUnassignedSubtournamentMembers", { subtournament_id: subtournament_id }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (res) {
        let membersArr = []
        res.data.forEach((row) => {

          if (row.member_id > 0) {
            membersArr.push(row)
          }
        })

        let idBArr = []
        loopJsonB(jsonB[0], idBArr)

        for (let step = 0; step < idBArr.length; step++) {
          //   console.log(idBArr[step], membersArr[step], membersArr2[step]);

          let sId = idBArr[step].id
          let homeUserId = 0
          let visitorUserId = 0
          try {
            homeUserId = membersArr[idBArr[step].m1 - 1].member_id
          } catch (e) {

          }
          try {
            visitorUserId = membersArr[idBArr[step].m2 - 1].member_id
          } catch (e) {

          }

          axios.defaults.headers.common['Authorization'] = tokendata1;
          await axios.post("/generator/setMatchMembers", { sId: sId, subtournament_id: subtournament_id, homeUserId: homeUserId, visitorUserId: visitorUserId }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

        }

        authContext.changeRefreshView(authContext.refreshView + 1)
        addToast("Uczestnicy zostali rozstawieni", { appearance: 'success' });
        setspacingTournamentParticipantsStatus(true)


        setloading2(false)

      } else {
        setloading2(false)
      }

      function loopJsonB(jsonTMP, idBArr) {
        Object.entries(jsonTMP).forEach(
          ([key1, value1]) => {
            if (key1 === "sides") {
              if (value1.home.seed && value1.home.seed.sourceGame) {


                if (!value1.home.seed.sourceGame.sides.home.seed) {
                  if(value1.home.seed.sourceGame.sides.home.team.name.includes("UCZESTNIK")===false){
                    throw new Error('error=3 Rozstawiony recznie już ktoś jest');
                  }
                  idBArr.push({ id: value1.home.seed.sourceGame.id, m1: Number(value1.home.seed.sourceGame.sides.home.team.name.replaceAll("UCZESTNIK ", "")), m2: Number(value1.home.seed.sourceGame.sides.visitor.team.name.replaceAll("UCZESTNIK ", "")) })
                }


                loopJsonB(value1.home.seed.sourceGame, idBArr)

              }
              if (value1.visitor.seed && value1.visitor.seed.sourceGame) {


                if (!value1.visitor.seed.sourceGame.sides.visitor.seed) {
                  if(value1.visitor.seed.sourceGame.sides.home.team.name.includes("UCZESTNIK")===false){
                    throw new Error('error=3 Rozstawiony recznie już ktoś jest');
                  }
                  idBArr.push({ id: value1.visitor.seed.sourceGame.id, m1: Number(value1.visitor.seed.sourceGame.sides.home.team.name.replaceAll("UCZESTNIK ", "")), m2: Number(value1.visitor.seed.sourceGame.sides.visitor.team.name.replaceAll("UCZESTNIK ", "")) })

                }

                loopJsonB(value1.visitor.seed.sourceGame, idBArr)

              }
            }
          }
        );
      }
      setTimeout(() => {
        accRostawianieUczestników(subtournament_id, ttype)
      }, 200);

    } catch (e) {
      console.log(e)

      if(e.message.includes("error=3")){
        addToast("Wróć do kroku 1 i wygeneruj ponownie strukturę, ponieważ masz rozstawionych uczestników ręcznie!", { appearance: 'info' });
      setloading2(false)
      setspacingTournamentParticipantsStatusError(true)
      }else{
        addToast("Nie mogę utworzyć par automatycznie, proszę rozstawić uczestników ręcznie lub przez losowanie", { appearance: 'info' });
        setloading2(false)
        setspacingTournamentParticipantsStatusError(true)
      }
      

    }
  }

  return (
    <>
      <Segment raised>
        <div style={{ marginBottom: 10, overflowX: "auto", width: width < 700 ? (width - 130) : "100%" }} >
          <StepGroup ordered fluid >
            {questions.map(function (question, index) {
              return <Step key={index} completed={question.status === 'completed' && step !== index ? true : false} active={step === index ? true : false} disabled={question.status === 'disabled' && step !== index ? true : false}>

                <Step.Content>
                  <Step.Title className='stepStyle'>{question.qname}</Step.Title>
                  <Step.Description>{question.qdisc ? question.qdisc : ''}</Step.Description>
                </Step.Content>
              </Step>
            })}
          </StepGroup>
        </div>
        {TournamentTypeSelectName != null ?
          <Header as='h3' color='orange' icon textAlign='center' style={{marginTop:50,marginBottom:50}}>
            <Icon name='winner' circular className='coffeAnime'/>
            <Header.Subheader>WYBRANY SYSTEM TURNIEJU</Header.Subheader>
            <Header.Content>{TournamentTypeSelectName}</Header.Content>
          </Header>
          : ""}

        <Button.Group widths='3' size='mini'>
          {step === 0 ? "" :
            <Button onClick={() => nexStep(-1)} size='mini' labelPosition='left' icon='arrow circle left' content='Poprzednie' />
          }
      
          {TournamentTypeSelectName === null || step === 2 || !form.tournamentCreate ? "" : <>  <Button.Or text="" />
            <Button onClick={() => nexStep(+1)} size='mini' labelPosition='right' icon='arrow circle right' content='Następne' />
          </>}
        </Button.Group>
        <Header>
          {questions[step].qname} - {questions[step].qdisc}
        </Header>
        {step === 0 ?
          <>

            {TournamentType && TournamentType.length > 0 ?
              TournamentType.map((row, i) =>

                <Grid>
                  <Grid.Row style={{ margin: 0, padding: 5 }}>
                    <Grid.Column mobile={9} tablet={12} computer={width > 1500 ? 14 : 12}>
                      <Button  size='small' fluid onClick={() => changeTournamentType(row)}>{getIconTournamentType(row.value)}{row.text}</Button>
                    </Grid.Column>
                    <Grid.Column mobile={7} tablet={4} computer={width > 1500 ? 2 : 4}>
                      <Button icon labelPosition='left' size='small' fluid color='grey' onClick={() => row.value ? authContext.changeModalInfo({ nameModal: JSON.parse(row.value).id, name: JSON.parse(row.value).name, value: "" }) : ""}> <Icon name='sticky note' /> {width > 800 ? "ZOBACZ OPIS" : "OPIS"}</Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>


              ) : ""}

          </>

          : ""
        }

        {step === 1 ?
          <>
            <Message size='tiny' visible>
              <p><Icon name="info circle" />Aby rozstawić uczestników ręcznie, kliknij w numer uczestnika.<br />
                <Icon name="info circle" />Uczestnicy którzy nie będą rozstawienie zostaną im wylosowane wolne miejsca.<br />
                <Icon name="info circle" />Następnie kliknij poniższy przycisk "ZATWIERDZAM ROZSTAWIENIE UCZESTNIKÓW"</p>
            </Message>
            {ttype === 1 ? <>
             

              <Button.Group widths='3' >
                <Button icon labelPosition='left'  loading={loading2} disabled={loading2} color='blue'  onClick={() => spacingTournamentParticipants(props.tournamentJson, props.subtournament_id, ttype)}>
                  <Icon name='tasks' />
                  ROZSTAW UCZESTNIKÓW AUTOMATYCZNIE PO GRUPACH
                </Button>
                <Button.Or text="" />
                { spacingTournamentParticipantsStatusError === false && positioningofplayer===false ?
                <Button icon labelPosition='right' loading={loading} disabled={loading} onClick={()=>clickPositioningofplayer()}> <Icon name='hand point up' />CHCĘ ROZSTAWIĆ UCZESTNIKÓW RĘCZNIE</Button>
                 : <Button icon labelPosition='right' color="brown" fluid loading={loading} disabled={loading} onClick={() => accRostawianieUczestników(props.subtournament_id, ttype)}><Icon name='check' />ZATWIERDZAM ROZSTAWIENIE UCZESTNIKÓW</Button>
               
                }

              </Button.Group>


              <BracketGen ttname={props.ttname} struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} third_place_encounter={props.third_place_encounter} ttype={props.ttype} remoteJson={props.tournamentJson ? props.tournamentJson : {}} onlyView={0} createTournament={true} tournamentId={props.subtournament_id} />
            </>
              : ""}
            {ttype === 2 ?
              <>
                <Button style={{ marginBottom: 10 }} color="brown" fluid loading={loading} disabled={loading} onClick={() => accRostawianieUczestników(props.subtournament_id, ttype)}>ZATWIERDZAM ROZSTAWIENIE UCZESTNIKÓW</Button>

                <BracketGroups struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} matches={props.matches ? props.matches : []} remoteGroups={props.tournamentGroups ? props.tournamentGroups : {}} onlyView={0} tournamentId={props.subtournament_id} />
              </>
              : ""}
          </>
          : ""
        }

        {step === 2 ?
          <>
            <Message size='tiny' info>
              <Message.Header>WAŻNE!</Message.Header>
              <p>Po zatwierdzeniu turnieju przystępujecie do gry, brak możliwości zmiany rodzaju turnieju.</p>
            </Message>
            <Button style={{ marginBottom: 10 }} color="brown" fluid loading={loading} disabled={loading} onClick={() => accTournament(props.subtournament_id)}>ZATWIERDŹ WYGENEROWANY TURNIEJ - ROZPOCZNIJ GRY</Button>
            {ttype === 1 ?
              <BracketGen ttname={props.ttname} struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} third_place_encounter={props.third_place_encounter} ttype={props.ttype} remoteJson={props.tournamentJson ? props.tournamentJson : {}} onlyView={1} tournamentId={props.subtournament_id} />
              : ""}
            {ttype === 2 ?
              <BracketGroups struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} matches={props.matches ? props.matches : []} remoteGroups={props.tournamentGroups ? props.tournamentGroups : {}} onlyView={1} tournamentId={props.subtournament_id} />
              : ""}
          </>
          : ""
        }


      </Segment>

      <Modal
        basic
        onClose={() => setOpen2(false)}
        onOpen={() => setOpen2(true)}
        open={open2}
        size='small'
      >
        <Header icon>
          <Icon name='table' />
          Do którego miejsca gramy drabinkę?
        </Header>
        <Modal.Content>
          <Form>
            <Form.Field
              control={Select} selectOnBlur={false}
              options={placeint}
              placeholder='Do którego miejsca gramy drabinkę?'
              onChange={(event, data) => {
                setgroupCount(data.value)
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='red' inverted onClick={() => {
            setgroupCount(0)
            setOpen2(false)
          }}>
            <Icon name='remove' /> Rezygnuje
          </Button>
          <Button color='green' loading={loading} disabled={loading} inverted onClick={() => {
            setOpen2(false)
            changeTournamentType(TournamentTypeSelected)
          }}>
            <Icon name='checkmark' /> Potwierdzam
          </Button>
        </Modal.Actions>
      </Modal>

    </>
  )
}

export default StepCreateTournamentGroups;