import React, { useEffect, useState, useContext } from 'react';
import { Icon, Header, Grid, Button, Modal, Image, Form, Input } from 'semantic-ui-react'
import { ConnectWS } from '../../../../ConnectWS';
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';


import { changeFormatDateTimeDB, removeEmojis, removeSpecialChars } from '../../../../myfunctions';

import LogoTeamLigaGenerator from '../../../../assets/logotgver1.svg';
import { Calendar } from 'primereact/calendar';
import GoogleSuggest from '../../../../tools/google/GoogleSuggest';
import GetEditorTG from '../../../../tools/GetEditorTG';


function AddMyActivities(props) {

  const [loading, setloading] = useState(false);
  const [isOpen, setisOpen] = useState(false)
  const [rte, setrte] = useState("");


  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);


  useEffect(() => {
    if (props.AddMyActivitiesOpen) {
      setisOpen(true)
    }

    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [props.AddMyActivitiesOpen])// eslint-disable-line react-hooks/exhaustive-deps

  let date = new Date();
  const [form, setForm] = useState({
    activity_name: '',
    trainer: '',
    activity_start_date: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 3),
    activity_stop_date: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 4),
    register_stop_date: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 3),
    members_limit: null,
    InputValuePlace: { value: '', data: null },
    img_url: "",
  });




  const submitAddTournament = async e => {
    e.preventDefault();

    if (form.InputValuePlace.place_id === null || typeof (form.InputValuePlace.place_id) === 'undefined') {
      addToast("Proszę uzupełnić prawidłowo lokalizację", { appearance: 'warning' });
      return
    }

    setloading(true)

    ConnectWS("/activity/createNewActivity", authContext.token, {
      activity_name: form.activity_name, trainer: form.trainer,
      activity_start_date: changeFormatDateTimeDB(form.activity_start_date),
      activity_stop_date: changeFormatDateTimeDB(form.activity_stop_date),
      register_stop_date: changeFormatDateTimeDB(form.register_stop_date)
      , members_limit: form.members_limit, description: rte, img_url: form.img_url, place: JSON.stringify(form.InputValuePlace)
    }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });

      } else if (response && response.status && Number(response.status) === 200) {

        //setrowDataJson(response.data)
        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
        closeModal()
      } else {


        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }

      setloading(false)


    });

  }




  function closeModal() {
    setisOpen(false)
    props.setAddMyActivitiesOpen(false)
  }

  

  return (
    <div style={{ marginTop: 10 }}>


      <Modal
        //onClose={() => closeModal()}
        open={isOpen}
        size='large'
      //dimmer='blurring'

      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h3' textAlign='center'>
                  <Icon name='add' color="red" />
                  <Header.Content>Dodaj Zajęcia/treningi</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image alt="logo" floated='right' size='big' style={{ maxWidth: 200 }} centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content scrolling style={{ backgroundColor: "#f5f5f5" }}>

          <Form onSubmit={submitAddTournament} size='mini' >
            <Form.Field
              control={Input}
              value={form.activity_name}
              onChange={(event, data) => setForm({ ...form, activity_name: removeSpecialChars(removeEmojis((data.value))) })}
              label='Nazwa zajęć'
              placeholder='Nazwa zajęć'
              required={true}

            />
            <Form.Field
              control={Input}
              value={form.trainer}
              onChange={(event, data) => setForm({ ...form, trainer: removeSpecialChars(removeEmojis((data.value))) })}
              label='Prowadzący'
              placeholder='Prowadzący'
              required={true}

            />
            <Form.Field
              control={Input}
              type='number'
              value={form.members_limit}
              onChange={(event, data) => setForm({ ...form, members_limit: data.value })}
              label='Limit uczestników'
              placeholder='Limit uczestników'
              required={true}

            />
            <GoogleSuggest form={form} setForm={setForm} />
            <Form.Field >
              <label>Rozpoczęcie zajęć</label>

              <Calendar value={form.activity_start_date} dateFormat="yy-mm-dd" locale="pl" onChange={(e) => e.value !== null ? setForm({ ...form, activity_start_date: e.value, register_stop_date: e.value }) : ""} showTime hourFormat="24" />

            </Form.Field>

            <Form.Field >
              <label>Zakończenie zajęć</label>

              <Calendar value={form.activity_stop_date} dateFormat="yy-mm-dd" locale="pl" onChange={(e) => e.value !== null ? setForm({ ...form, activity_stop_date: e.value }) : ""} showTime hourFormat="24" />

            </Form.Field>

            <Form.Field >
              <label>Zakończenie zapisów online</label>

              <Calendar value={form.register_stop_date} dateFormat="yy-mm-dd" locale="pl" onChange={(e) => e.value !== null ? setForm({ ...form, register_stop_date: e.value }) : ""} showTime hourFormat="24" />

            </Form.Field>

            <Form.Field
              control={Input}
              value={form.img_url}
              type='url'
              onChange={(event, data) => setForm({ ...form, img_url: data.value })}
              label='Url img'
              placeholder='Url img'


            />
            <Form.Field >
              <label><strong>Opis</strong></label>
              <GetEditorTG rte={rte} setrte={setrte} />
              
            </Form.Field>
            <Button style={{ marginTop: 17 }} color='red' loading={loading} disabled={loading} type='submit' fluid>DODAJ</Button>

          </Form>



        </Modal.Content>
        <Modal.Actions>

          <Button size="tiny" color='black' onClick={() => closeModal()}>
            ZAMKNIJ
          </Button>

        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default AddMyActivities;