import React, { useState, useEffect, useContext } from 'react';
import { Icon, Form, Button, Input, Message, Container, FormTextArea } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { ConnectWS } from '../../../../ConnectWS';
import useAuth from '../../../../hooks/useAuth';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useToasts } from 'react-toast-notifications';
import { getLimitUsersPackage, removeEmojis, removeSpecialChars, txtCase } from '../../../../myfunctions';
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import AdSense from 'react-adsense';
import { useTranslation } from 'react-i18next'

function MyTournamentAddUsers(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const [limitUsers, setlimitUsers] = useState(0) // eslint-disable-line no-unused-vars
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();
  const isDemo = useCheckUserIsDemo()

  useEffect(() => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MyTournamentAddUsers")
    }

    if (props.tournamentFree) {
      setlimitUsers(getLimitUsersPackage(props.tournamentFree))
    }

    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  const [formAddUsers, setFormAddUsers] = useState({
    name: "",
    surname: "",
    email: "",
    additional_info: "",
  });

  function showIndoAddUsersS(nazwa,message,error) {
    authContext.changeModalInfo({ viewClickOff: true, nameModal: "ADDTS", name: nazwa, value: message, error:error })
  }


  const addDBUsersToTournament = e => {
    e.preventDefault();

    setloading(true)
    let nametmp
    if (props.subTurnamentType === "USER") {
      nametmp = txtCase(formAddUsers.name.trim()) + " " + txtCase(formAddUsers.surname.trim())
    } else {
      nametmp = txtCase(formAddUsers.name.trim())
    }
    let additional_info = null
    if (props.show_members_additional_info) {
      additional_info = formAddUsers.additional_info
    }

    ConnectWS("/generator/addMemberToTournament", authContext.token, { subtournament_id: props.subTournamentId, name: nametmp, email: formAddUsers.email, additional_info: additional_info, source_guest: props.registrationTournament, limit_users: limitUsers }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        setFormAddUsers({
          name: "",
          surname: "",
          email: "",
          additional_info: ""
        })
        if (props.registrationTournament) {
          showIndoAddUsersS("Potwierdź zapisy",<> Aby zakończyć proces zapisu, prosimy o potwierdzenie swojego adresu e-mail.<br />
            Właśnie wysłaliśmy do Ciebie wiadomość z linkiem weryfikacyjnym. Kliknij na ten link, aby potwierdzić swoje zapisy.

          </>,false)
        } else {
          addToast(response.data.message, { appearance: 'success' });
        }

        authContext.changeRefreshView(authContext.refreshView + 1)

      } else {
        if(response.includes("nazwa jest niezgodna")){
          showIndoAddUsersS("Informacja",response,true)
        }else{

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
        //console.log(response);
      }
      setloading(false)


    });

  }


  function addTestMembersToSubtournament() {


    setloading(true)


    ConnectWS("/generator/addTestMembersToSubtournament", authContext.token, { subtournament_id: props.subTournamentId }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {

        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)

      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }
      setloading(false)


    });

  }


  return (
    <>
      {isDemo && props.registrationTournament === false ? <Container style={{ marginBottom: 10 }} textAlign='center'><Button size='small' color='blue' fluid onClick={() => addTestMembersToSubtournament()}>KLIKNIJ TUTAJ I UTWÓRZ UCZESTNIKÓW TESTOWYCH</Button>  </Container> : ""}
      <Form size='tiny' onSubmit={addDBUsersToTournament}>
        {props.subTurnamentType === "USER" ?
          <>
            <Form.Field
              control={Input}
              value={formAddUsers.name}
              onChange={(event, data) => setFormAddUsers({ ...formAddUsers, name: removeSpecialChars(removeEmojis(data.value)) })}
              label={t("WidokTurnieju.zapisy_imie")}
              required={props.subTurnamentType === "USER" ? true : false}
              placeholder={t("WidokTurnieju.zapisy_imie")}


            />
            <Form.Field
              control={Input}
              value={formAddUsers.surname}
              onChange={(event, data) => setFormAddUsers({ ...formAddUsers, surname: removeSpecialChars(removeEmojis(data.value)) })}
              required={props.subTurnamentType === "USER" ? true : false}
              label={t("WidokTurnieju.zapisy_nazwisko")}
              placeholder={t("WidokTurnieju.zapisy_nazwisko")}


            />
          </>
          : <Form.Field
            control={Input}
            value={formAddUsers.name}
            required={props.subTurnamentType !== "USER" ? true : false}
            onChange={(event, data) => setFormAddUsers({ ...formAddUsers, name: removeSpecialChars(removeEmojis(data.value)) })}
            label={t("WidokTurnieju.zapisy_druzyna")}
            placeholder={t("WidokTurnieju.zapisy_druzyna")}


          />}

        {props.show_members_additional_info ? <FormTextArea label={t("WidokTurnieju.zapisy_dodatkowe_info")}
          value={formAddUsers.additional_info}
          onChange={(event, data) => setFormAddUsers({ ...formAddUsers, additional_info: removeSpecialChars(removeEmojis(data.value)) })}
          placeholder={t("WidokTurnieju.zapisy_dodatkowe_info")}
        /> : ""}


        <Form.Field
          control={Input}
          value={formAddUsers.email}
          onChange={(event, data) => setFormAddUsers({ ...formAddUsers, email: data.value })}
          label={t("WidokTurnieju.zapisy_email")}
          required={props.registrationTournament === true ? true : false}
          placeholder={t("WidokTurnieju.zapisy_email")}
          type='email'
          disabled={props.tournamentFree === 0 && authContext.user.subscription_info.subscription_active !== true ? true : false || isDemo ? true : false}
        />
        {isDemo ?
          <Message>
            <Message.Header><Icon name="lock" color="red" /> Pole e-mail zablokowane</Message.Header>
            <p>Pole e-mail zablokowane na koncie testowym</p>
          </Message>
          : ''}
        {props.tournamentFree === 0 && authContext.user.subscription_info.subscription_active !== true ?
          <Message>
            <Message.Header><Icon name="lock" color="red" /> Pole e-mail zablokowane</Message.Header>
            <p>Pole dostępne w srebrnym, złotym lub diamentowym pakiecie.</p>
          </Message>
          : ''}
        {props.registrationTournament === false && isDemo === false && props.tournamentFree !== 0 ?
          <Message info size='small'>
            <Message.Header><Icon name='info circle' /> Ważna informacja.</Message.Header>
            <p><b>Podany adres e-mail nie jest dostępny dla innych osób,</b>  nie ma go na żadnej podstronie z wynikami!. Podamy adres e-mail jest widoczny <b>tylko dla organizatora</b> turnieju w celu weryfikacji unikalności danego uczestnika.</p>

          </Message>
          : ''}
        {props.tournamentFree === 0 && props.google_ads === 1 && Number(process.env.REACT_APP_IN_GOOGLEROFF) === 0 ?
          <Message>
            <Message.Header><Icon name="adversal" />Reklama Google</Message.Header>

            <AdSense.Google
              client='ca-pub-7406426760252636'
              slot='8871959796'
              style={{ display: 'block', textAlign: "center" }}
              format='auto'
              response={true}
            />

          </Message>
          : ""}
        <Button color={props.registrationTournament ? "red" : "grey"} loading={loading} disabled={loading} fluid type='submit'>{props.registrationTournament ? t("WidokTurnieju.zapisy_btn") : "DODAJ"}</Button>
      </Form>

    </>
  )
}

export default MyTournamentAddUsers;